// .container {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: center;

//   >* {
//     height: 100%;
//   }

// }

// .mapContainer {
//   border-radius: 8px;
// }

// .rightWrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 350px;
// }
.containerMap {
  position: relative;
  // background-color: salmon;
  top: 0;
  height: 100%;
  width: 100%;
  .searchBox {
    position: relative;
    // top: 3rem;
    z-index: 1000;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .search {
      padding-inline: 8px;
      width: 100%;
      position: absolute;
      top: 8px;
    }
  }
}
