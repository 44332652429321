#waveform ::part(cursor):before {
  margin-top: 100%;
  content: '';
  display: inline-block;
  width: 11px;
  height: 11px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
  border-radius: 32px;
  background-color: #919EB0;
}
#waveform.transparent ::part(cursor):before {
  background-color: transparent;
}
#waveform ::part(region) {
  font-family: fantasy;
}