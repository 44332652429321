@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  border-radius: 8px 0 0 8px;
  width: 300px;
  display: flex;
  flex-direction: column;

  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid $NEUTRAL-30;

    >h3 {
      font-size: 16px;
      font-weight: 500;
      color: $NEUTRAL-100;
      flex-grow: 1;
    }

    >button {
      display: grid;
      place-items: center;
    }
  }

  .content {
    padding: 16px;
    flex-grow: 1;
  }

  .actions {
    padding: 16px;
    background-color: $NEUTRAL-10;
    border: 1px solid $NEUTRAL-30;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    >button {
      padding: 8px 12px;
      border: 2px solid transparent;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;

      &:first-child {
        border-color: $NEUTRAL-40;
        color: $NEUTRAL-60;
      }

      &:nth-child(2) {
        border-color: $PRIMARY-MAIN;
        background-color: $PRIMARY-MAIN;
        color: $NEUTRAL-10;
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:first-child) {
    padding-top: 16px;
  }
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL-30;
  }

  .sectionTitle {
    font-size: 14px;
    font-weight: 500;
  }
}

.option {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  >span {
    font-size: 12px;
    font-weight: 500;
    color: $PRIMARY-MAIN;
  }
}