@use "../../../theme/variables.scss" as *;

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: $SHADOW-500;
  padding: 10px;
  border-radius: 8px;

  >img {
    width: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    background-color: $NEUTRAL-30;
  }

  >* {

    &:last-child {
      flex-grow: 1;
    }
  }
}