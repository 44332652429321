@import "../../../theme/variables.scss";
.wrapperRadioButton {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: $NEUTRAL-40;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  transition: 0.4s;

  .dotsChecked {
    width: 70%;
    height: 70%;
    background-color: $NEUTRAL-10;
    border-radius: inherit;
  }
}
.wrapperRadioButton[isactive="true"] {
  opacity: 1;
  background-color: $PRIMARY-MAIN;
  .dotsChecked {
    width: 40%;
    height: 40%;
  }
}
.wrapperRadioButton[is-disabled="true"] {
  background-color: $NEUTRAL-20;
  border: 1px solid $NEUTRAL-30;
  cursor: not-allowed;
  .dotsChecked {
    display: none;
  }
}
