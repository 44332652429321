@use "./theme/variables.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: $BASE-FONT-FAMILY;
  ins {
    // as underline tag <u/>
    text-decoration: underline;
  }
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
}
