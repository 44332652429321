body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@mixin flexRow($gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flexCol($rowGap) {
  display: flex;
  flex-direction: column;
  row-gap: $rowGap;
}

@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin flexColumnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
