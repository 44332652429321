@import "../../../../theme/variables.scss";

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  .top {
    flex: 1;
    background-image: url("../../../../assets/Images/login-bg-header.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
  }

  .bottom {
    flex: 1;
    background: rgba(212, 212, 232, 0.1);
    background-image: url("../../../..//assets/Images/header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 24px;
    .loginCard {
      background-color: $NEUTRAL-10;
      padding: 32px;
      border-radius: 8px;
      width: 425px;
      max-width: 90vw;
      box-shadow: 0px 8.936px 20.852px -8.936px rgba(24, 39, 75, 0.12),
        0px 14.894px 47.661px -5.958px rgba(24, 39, 75, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      > * {
        width: 100%;

        &.header {
          display: flex;
          align-items: center;
          flex-direction: column;
          row-gap: 1rem !important;
          > * {
            width: 100%;
          }

          > img {
            width: 52px;
            object-fit: contain;
            // padding-bottom: 32.85px;
          }

          > h3 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }

          > div {
            // padding-top: 24px;
            display: flex;
            flex-direction: column;
          }
        }

        &.main {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .form {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .info {
              display: flex;
              align-items: center;
              gap: 4px;
              color: $NEUTRAL-90;
              .iconInfo {
                transform: rotate(180deg);
              }
              > * {
                font-size: 12px;

                &:first-child {
                  flex-shrink: 0;
                }
              }
            }
          }

          .otpForm {
            display: flex;
            flex-direction: column;
            transition: 4s;
            > span {
              font-size: 16px;
              font-weight: 600;
              padding-bottom: 8px;
            }

            > p {
              font-size: 14px;
              color: $NEUTRAL-70;
              padding-bottom: 24px;

              > span {
                color: #2e3192;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .errorWrapper {
            padding-top: 12px;
          }

          .resendCode {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // margin-bottom: 32px;
            .text {
              color: #1571de;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
              transition: 0.2s;
              &:hover {
                color: #2e3192;
              }
              &[is_disabled_btn="true"] {
                color: #666;
                cursor: not-allowed;
              }
            }

            .timeLftLoader {
              display: flex;
              gap: 0;
              position: relative;

              .loaderContainer {
                position: absolute;
                right: 1.5rem;
                display: flex;
                width: 40px;
                height: 40px;
              }
              .loaderPath {
                stroke-dasharray: 100;
                transition: stroke-dashoffset 1s linear;
              }

              .loader {
                width: 45%;
                height: 45%;
                border: 3px solid #5497e9;
                border-top-color: transparent;
                border-radius: 50%;

                animation: rotate 300s linear infinite;
                &[is_paused_time_left="false"] {
                  border-color: #1571de;
                }
              }

              .paused {
                animation-play-state: paused;
              }

              .number {
                color: #000;
                font-size: 14px;
                font-weight: 600;
                line-height: 145%;
              }
            }
          }

          > button {
            background-color: #2e3192;
            padding: 8px 12px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL-10;
          }
        }
      }
    }

    .privacyPolicy {
      display: flex;
      flex-direction: column;
      align-items: center;

      > span {
        font-size: 16px;
        font-weight: 600;
      }

      > p {
        font-size: 14px;
        color: $NEUTRAL-70;
        padding-bottom: 12px;
      }

      > button {
        text-decoration: underline;
        font-size: 14px;
        color: #2e3192;
        font-weight: 500;
      }
    }
  }

  .footer {
    height: 52px;
    background-color: #f0f0ff;
    display: grid;
    place-items: center;
    font-size: 14px;
    color: #666;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
    border-top-color: #1571de;
  }
  to {
    transform: rotate(360deg);
    // border-color: #1571de;
  }
}
