@import "../../../../theme/variables.scss";

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 1440px;
  margin-inline: auto;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    >h3,
    >span {
      padding-left: 4px;
      font-size: 24px;
      font-weight: 500;
      line-height: 125%;
    }

    .customDatePickerStyle {
      border: none !important;

      >svg {
        fill: $NEUTRAL-70 !important;
      }

      >span {
        color: $NEUTRAL-70 !important;
        font-size: 24px !important;
      }

    }
  }

  .content {
    padding: 24px;
    background-color: $NEUTRAL-10;
    border-radius: 8px;
    box-shadow: $SHADOW-300;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .top {
      display: flex;
      align-items: center;
      gap: 8px;

      .profile {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-grow: 1;

        >img {
          width: 65px;
          aspect-ratio: 1;
          border-radius: 50%;
        }

        .details {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {
            font-size: 14px;
            color: $NEUTRAL-100;
            line-height: 142.857%;
          }

          >p {
            font-size: 12px;
            line-height: 133.333%;
            color: $NEUTRAL-60;
          }

          .status {
            align-self: flex-start;
            padding: 2px 8px;
            border-radius: 17px;
            min-width: 84px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 133.333%;

            &.active {
              background-color: #CEEAD3;
              color: $SUCCESS-MAIN;
            }
          }
        }
      }


      .attendance {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px;

        >div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 2px;

          >span {
            font-size: 12px;
            line-height: 133.333%;
            color: $NEUTRAL-70;
          }

          >p {
            font-size: 12px;
            line-height: 133.333%;
            font-weight: 500;
          }
        }

        >span {
          background-color: $PRIMARY-SURFACE;
          display: grid;
          place-items: center;
          padding-inline: 8px;
          font-weight: 20px;
          font-weight: 500;
          color: $PRIMARY-MAIN;
          border-radius: 8px;
          min-width: 39px;
        }
      }
    }
  }
}