@import '../../../theme/variables.scss';

.container {
  padding-inline: 8px;
  border-bottom: 1px solid $NEUTRAL-30;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 48px;

  .icon {

    &.transform {
      transform: rotate(180deg);
    }
  }

  .text,
  .icon {
    color: $NEUTRAL-50 !important;
  }

  &.active {
    border-bottom: 2px solid $PRIMARY-MAIN;

    .text,
    .icon {
      color: $PRIMARY-MAIN !important;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 2px);
  }
}