@use "theme/color";
@use "theme/mixin";

.wrapLoadingSpinner {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  padding: 1rem;
  .wrapText {
    @include mixin.flexCol(4px);
    align-items: center;
    > .titleLoad {
      color: color.$NEUTRAL-100;
    }
    .description {
      color: color.$NEUTRAL-80;
      max-width: 400px;
    }
  }
}
