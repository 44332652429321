@use "theme/color.scss" as c;
.containerDropdownMoreCircle {
  position: relative;
  .target {
    width: max-content;
    cursor: pointer;
  }
}
.wrapperDropdownMoreCirle {
  width: max-content;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.168);
  background-color: c.$NEUTRAL-10;
  position: absolute;
  right: 0;
  z-index: 99;
  border-radius: 8px;
  div {
    padding: 6px 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    color: c.$PRIMARY-MAIN;
    cursor: pointer;
    background-color: c.$NEUTRAL-10;
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:hover {
      background-color: c.$PRIMARY-SURFACE;
    }
    span {
      color: c.$PRIMARY-MAIN;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
