@import '../../../theme/variables.scss';

.container {
  display: flex;
  flex-direction: column;

  &[variant='tab'] {
    .each {
      padding: 8px 12px;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
      color: $PRIMARY-MAIN;
      cursor: pointer;

      &[isActive='true']{
        background-color: $PRIMARY-SURFACE;
      }
    }
  }

}