@import "../../../../theme/variables.scss";
.flexInput {
  display: flex;
  gap: 1rem;
}

.mainContainerCenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $PRIMARY-BACKGROUND;
}
.wrapperAddEmployee {
  max-width: 1440px;
  padding: 21px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem !important;
  @media (max-width: 1024px) {
    row-gap: 0;
  }
  .buttonBack {
    display: flex;
    gap: 12px;
    align-items: center;
    .iconBack {
      cursor: pointer;
      transform: rotate(90deg);
    }
    .text {
      color: $NEUTRAL-100;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  .container {
    padding: 1rem 24px;
    box-shadow: 0px 6px 8px $NEUTRAL-30;
    border-radius: 6px;
    display: flex;
    gap: 24px;
    height: calc(100% - 3rem);
    width: 100%;
    background-color: $NEUTRAL-10;
    @media (max-width: 768px) {
      height: auto !important;
    }
    .sideProfile {
      display: flex;
      flex-direction: column;
      width: 237px;
      align-items: center;
      row-gap: 4px;
      .imageProfile {
        display: flex;
        width: 120px;
        height: 120px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background-color: $PRIMARY-SURFACE;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        div {
          border-radius: inherit;
          width: 36px;
          height: 36px;
          padding: 4px;
          background-color: $PRIMARY-MAIN;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .imageProfile[is-choose="true"] {
        padding: 0 !important;
        .photos {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: $NEUTRAL-30;
        margin-block: 12px;
      }
      .menu {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        div {
          display: flex;
          gap: 4px;
          align-items: center;
          .number {
            padding: 8px;
            border-radius: 8px;
            background-color: $NEUTRAL-20;
            width: 32px;
            height: 32px;
            justify-content: center;
            display: flex;
            span {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: $NEUTRAL-60;
            }
          }
          h3 {
            padding: 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            width: 185px;
            color: $NEUTRAL-90;
          }
        }
        .step {
          border: 1px dashed $NEUTRAL-30;
          height: 20px;
          width: 1px;
          position: relative;
          left: 15px;
        }
      }
      .menu[is-active="true"] {
        div {
          .number {
            background-color: $PRIMARY-SURFACE;
            span {
              color: $PRIMARY-MAIN;
            }
          }
          h3 {
            color: $PRIMARY-MAIN;
          }
        }
      }
      .sideMenu {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        .wrapFirstStep {
          position: relative;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          .step1 {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            .number {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px;
              border-radius: 8px;
              background-color: $PRIMARY-SURFACE;
              span {
                color: $PRIMARY-MAIN;
                font-size: 12px;
                font-weight: 500;
              }
            }
            span {
              padding: 8px;
              color: $PRIMARY-MAIN;
              font-size: 12px;
              font-weight: 500;
            }
          }
          .childStep {
            position: relative;
            left: 2rem;
            display: flex;
            gap: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;

            > span {
              color: $NEUTRAL-60;
              font-size: 12px;
              font-weight: 500;
            }
            .numChild {
              padding-block: 4px;
              width: 24px;
              height: 24px;
              background-color: $NEUTRAL-20;
              border-radius: 5px;
              font-size: 12px;
              color: $NEUTRAL-60;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .childStep[is-active="true"] {
            > .numChild {
              background-color: $PRIMARY-SURFACE;
              color: $PRIMARY-MAIN;
            }
            > span:nth-child(2) {
              color: $PRIMARY-MAIN;
            }
          }
          .dividerStep {
            position: absolute;
            height: 200px;
            width: 1px;
            border: 1px dashed $NEUTRAL-30;
            left: 1rem;
            bottom: -8px;
          }
        }
        .step2,
        .step3 {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 4px;
          position: relative;
          .number {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 8px;
            background-color: $NEUTRAL-20;
            span {
              color: $NEUTRAL-60;
              font-size: 12px;
              font-weight: 500;
            }
          }
          span {
            padding: 8px;
            color: $NEUTRAL-60;
            font-size: 12px;
            font-weight: 500;
          }
          .dividerStep {
            position: absolute;
            height: 20px;
            width: 1px;
            border: 1px dashed $NEUTRAL-30;
            left: 1rem;
            bottom: -28px;
          }
        }
        .step2 {
          margin-bottom: 20px;
        }
      }
    }
    .lineSeparator {
      height: auto;
      width: 1px;
      background-color: $NEUTRAL-30;
      margin-left: 24px;
    }
    .mainContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      height: auto;
      .wrapperStepper {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $NEUTRAL-10;
        height: 100px;
        row-gap: 1rem;
        h1 {
          color: $NEUTRAL-100;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
        }
      }
      .wrapperInput {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        height: 100%;
        justify-content: space-between;
        @media (max-width: 768px) {
          overflow-y: unset;
        }
        width: 100%;
        row-gap: 1rem;

        .sticky {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          @media (max-width: 768px) {
            row-gap: 0;
          }
          position: sticky;
          top: 0;
          justify-content: center;
          background-color: $NEUTRAL-10;
          z-index: 2;
          h2 {
            color: $NEUTRAL-100;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
          }
          .divider {
            top: 0;
            width: 100%;
            height: 1px;
            background-color: $NEUTRAL-30;
            margin-top: 8px;
          }
          div:nth-child(1) {
            @media (max-width: 768px) {
              padding-top: 8px;
            }
            width: 100%;
            display: flex;
            justify-content: space-between;
            h2 {
              font-size: 20px;
              color: $NEUTRAL-100;
            }
            .icon {
              cursor: pointer;
              color: $DANGER-BASE;
              height: 24px;
              width: 24px;
              flex-shrink: 0;
            }
          }
        }
        .containInput {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          width: 100%;
          .badgeInfo {
            display: flex;
            padding: 8px 12px;
            align-items: center;
            border-radius: 8px;
            background-color: $PRIMARY-SURFACE;
            gap: 8px;
            span {
              color: $PRIMARY-MAIN;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .wrapperAddOption {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            .isAddOption {
              display: flex;
              max-width: 335px;
              width: 100%;
              padding: 24px;
              flex-direction: column;
              row-gap: 24px;
              border-radius: 8px;
              background: $NEUTRAL-10;
              box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
                0px 8px 24px -4px rgba(24, 39, 75, 0.08);
              div:nth-child(1) {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                justify-content: center;
                align-items: center;
                img {
                  width: 69px;
                  height: 69px;
                  flex-shrink: 0;
                  object-fit: contain;
                }
                h2 {
                  color: $NEUTRAL-100;
                  font-size: 14px;
                  font-weight: 400;
                }
                p {
                  color: $NEUTRAL-60;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }
          .flexInput {
            display: flex;
            gap: 1rem;
          }
        }

        .wrapperStaffing {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          padding: 2px;
          .headerStaffing {
            color: $NEUTRAL-100;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
          }
          .inputStaffing {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            box-shadow: 2px 2px 10px $NEUTRAL-30;
            border-radius: 8px;
            padding: 1rem;
            h3 {
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              color: $NEUTRAL-100;
            }
            .between {
              display: flex;
              gap: 1rem;
            }
            .wrapperUploadDocument {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              > h2 {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $NEUTRAL-100;
              }
              .uploadDocument {
                //demm2
                padding: 12px;
                border: 1px dashed $PRIMARY-MAIN;
                border-radius: 8px;
                .between {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  align-items: center;
                  div:nth-child(1) {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 100%;
                    .wrapperIcon {
                      padding: 1rem 12px;
                      background-color: $PRIMARY-SURFACE;
                      width: max-content;
                      border-radius: 6px;
                    }
                    .wrapperIcon[is-mobile="true"] {
                      padding: 0;
                    }
                    .wrapperChange {
                      display: flex;
                      flex-direction: column;
                      gap: 4px;
                      width: 100%;
                      h3 {
                        color: $PRIMARY-MAIN;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        div:nth-child(1) {
                          display: flex;
                          justify-content: space-between;
                          width: 100%;
                          gap: 8px;
                          .fileName {
                            font-size: 12px;
                            font-size: 14px;
                            font-weight: 500;
                            color: $NEUTRAL-90;
                            max-width: 640px;

                            overflow: hidden;
                            display: -webkit-box;
                            line-clamp: 1;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                          }
                          .gap {
                            display: flex;
                            gap: 4px;
                            align-content: center;
                            .icon {
                              color: $DANGER-MAIN;
                              width: 16px;
                              height: 16px;
                              cursor: pointer;
                              flex-shrink: 0;
                            }
                            > span {
                              color: $PRIMARY-MAIN;
                              font-size: 12px;
                              font-weight: 500;
                              cursor: pointer;
                              min-width: 50px;
                            }
                          }
                        }
                      }
                      > .desc {
                        color: $NEUTRAL-70;
                        text-align: left;
                        font-size: 12px;
                      }
                      .loading {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        .loader {
                          width: 100%;
                          height: 8px;
                          background-color: $NEUTRAL-30;
                          border-radius: 8px;
                          > span {
                            height: 8px;
                            border-radius: 8px;
                            background: linear-gradient(
                              90deg,
                              #417e98 0%,
                              #2c5364 100%
                            );
                          }
                        }
                        .percentage {
                          font-size: 12px;
                          font-weight: 500;
                          color: $NEUTRAL-50;
                        }
                      }
                    }
                  }
                  .btnFile {
                    border: 2px solid $PRIMARY-MAIN;
                    padding: 8px 4px;
                    border-radius: 8px;
                    display: flex;
                    width: max-content;
                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          .inputOrganization {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            box-shadow: 2px 2px 10px $NEUTRAL-30;
            border-radius: 8px;
            padding: 1rem;
            margin-top: 8px;
            .between {
              display: flex;
              gap: 1rem;
            }
          }
        }
        .lastUploadDocument {
          display: flex;
          flex-direction: column;
          row-gap: 100px;
          padding-bottom: 100px;
          > h2 {
            color: $NEUTRAL-100;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
          }
          .wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            .upload {
              display: flex;
              flex-direction: column;
              row-gap: 1rem;
              width: 438px;
              .card1 {
                border: 1px dashed $PRIMARY-MAIN;
                padding: 28px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                row-gap: 7px;
                align-items: center;
                background-color: #f0f0ff33;
                > h2 {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  color: $PRIMARY-MAIN;
                }
                > span {
                  font-size: 14px;
                  color: $NEUTRAL-70;
                }
              }
              .uploading {
                padding: 8px;
                box-shadow: 2px 2px 6px $NEUTRAL-30;
                border-radius: 8px;
                max-width: 440px;
                position: relative;
                .between {
                  display: flex;
                  gap: 12px;
                  .iconFolder {
                    padding: 1rem 12px;
                    background-color: #f0f0ff;
                    border-radius: 4px;
                  }
                  .wrapperProgress {
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                    width: 100%;
                    .name {
                      font-size: 14px;
                      font-weight: 500;
                      color: $NEUTRAL-90;
                      max-width: 640px;
                      overflow: hidden;
                      display: -webkit-box;
                      line-clamp: 1;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      text-overflow: ellipsis;
                    }
                    .fileSize {
                      font-size: 14px;
                      font-weight: 500;
                      color: $NEUTRAL-50;
                    }
                    .loading {
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      width: 100%;
                      .wrapperUploader {
                        width: 100%;
                        display: flex;
                        height: 8px;
                        background-color: $NEUTRAL-30;
                        border-radius: 8px;
                        .progress {
                          height: 8px;
                          background: linear-gradient(
                            90deg,
                            #417e98 0%,
                            #2c5364 100%
                          );
                          border-radius: 8px;
                        }
                      }
                      span {
                        font-size: 12px;
                        color: $NEUTRAL-70;
                        font-weight: 500;
                      }
                    }
                  }
                  .closeIcon {
                    position: absolute;
                    right: 8px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .viewLoadingCreate {
        background-color: $NEUTRAL-10;
        border-radius: 8px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        .loadingElement {
          height: 60px;
          width: 100%;
        }
      }
    }
  }
}
.nextAndPrevButton {
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 8px;
}
.containerModal {
  padding: 1rem;
  .modalAddEmployee {
    padding: 1rem 20px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 409px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 100%;
        align-items: center;
        display: flex;
        gap: 10px;

        > h2 {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .closeModal {
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    p {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
    }
    .buttonModal {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: right;
    }
  }
}
.container[is-mobile="true"] {
  padding: 1rem 1rem;
  gap: 0;
}
.wrapperInput::-webkit-scrollbar {
  display: none;
}
.wrapperInput {
  scrollbar-width: thin;
  -webkit-scrollbar-width: thin;
  padding-right: 1rem;
  overflow-y: scroll;
  scroll-margin-block-start: 20px !important;
  scrollbar-width: 40px;
  @media (max-width: 768px) {
    padding-right: 0;
  }
}
.wrapperInput::-webkit-scrollbar-track {
  background-color: transparent;
  scrollbar-width: 40px;
}
.wrapperInput::-webkit-scrollbar-thumb {
  background-color: $NEUTRAL-40;
  scrollbar-width: 40px;
}
.betweenInput {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.betweenInput[is-mobile="true"] {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .betweenInput {
    gap: 12px !important;
  }
  .imageProfile {
    display: flex;
    width: 120px;
    height: 120px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: $PRIMARY-SURFACE;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    div {
      border-radius: inherit;
      width: 36px;
      height: 36px;
      padding: 4px;
      background-color: $PRIMARY-MAIN;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .imageProfile[is-choose="true"] {
    padding: 0 !important;
    .photos {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .wrapperAddEmployee {
    .wrapperUploadImage {
      display: flex;
      justify-content: center;
      .imgMobileInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        width: 100%;
        margin-top: 42px;
        .mobileStep {
          display: flex;
          width: 100%;
          justify-content: space-between;
          > span {
            color: $NEUTRAL-50;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .lastUploadDocument {
    .wrapper {
      .upload {
        .card1 {
          padding-inline: 1rem !important;
          > h2 {
            font-size: 1rem !important;
          }
          > span {
            font-size: 14px !important;
            text-align: center;
          }
        }
        .uploading {
          .between {
            .wrapperProgress {
              .name {
                display: none;
                width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

// .wrapperChange {
//   h3 {
//     div:nth-child(1) {
//       .fileName {
//         width: 154px !important;
//       }
//     }
//   }
// }

.wrapAddNewSquad {
  width: 427px;
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  > div {
    &:first-child {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
