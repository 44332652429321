@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.containerDetailGuardPos {
  @include mixin.flexCol(20px);
  width: 100%;
  justify-content: flex-start;
  // padding: 20px;
  max-width: 1440px;
  height: 100%;
  padding-top: 20px;
  .header {
    @include mixin.flexRow(12px);
    align-items: center;
    justify-content: space-between;
    padding-inline: 20px;

    > div {
      @include mixin.flexRow(12px);
      align-items: center;

      .back {
        cursor: pointer;
        height: 20px;
        width: 20px;
        color: color.$NEUTRAL-100;
      }
      > h3 {
        color: color.$NEUTRAL-100;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .settings {
      cursor: pointer;
      height: 32px;
      width: 32px;
      flex-shrink: 0;
      color: color.$NEUTRAL-60;
      transition: 0.3s;
      &:hover {
        color: color.$PRIMARY-HOVER;
      }
    }
  }
  .wrapTabs {
    width: 100%;
    @include mixin.flexBetween();
    align-items: center;
    padding-inline: 20px;
  }
  .viewerFilter {
    width: 100%;
    align-items: center;
    @include mixin.flexBetween();
    padding-inline: 20px;
    .filter {
      @include mixin.flexRow(12px);
      align-items: center;
    }
  }
  .viewerTableDetailGuard {
    padding-inline: 20px;
    @include mixin.flexCol(20px);
  }
  .wrapPhotoAndPointLocation {
    @include mixin.flexRow(20px);
    > .image,
    .map {
      border-radius: 16px;
      background-color: color.$NEUTRAL-10;
      padding: 1rem;
      width: 100%;
      height: 330px;
      @include mixin.flexCol(8px);
      border-radius: 12px;
      box-shadow: 0 2px 6px #1c1c1c23;

      > strong {
        color: color.$NEUTRAL-100;
        font-family: variables.$BASE-FONT-FAMILY;
        font-size: 16px;
        font-weight: 600;
      }
      > img {
        width: 100%;
        height: 266px;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid color.$NEUTRAL-30;
      }
      .map {
        padding: 0;
        width: 100%;
        border: 1px solid color.$NEUTRAL-30;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
  .tableRow {
    .viewColTable {
      @include mixin.flexCol(0);
      width: 100%;
      .tableHead {
        width: 100%;
        // @include mixin.flexBetween();
        padding: 12px 1rem;
        border: 1px solid color.$NEUTRAL-30;
        display: flex;
        background-color: color.$NEUTRAL-20;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        font-size: 14px;
        color: color.$NEUTRAL-100;
        // align-items: flex-start;
        // justify-content: flex-start;
        .viewRow {
          @include mixin.flexRow(3.2rem);
          width: 100%;

          > div {
            width: 40%;
            @include mixin.flexRow(4px);
            align-items: center;
            .icon {
              cursor: pointer;
              flex-shrink: 0;
            }
          }
        }
        .between {
          width: 125%;
          @include mixin.flexRow(30%);
          // @include mixin.flexBetween();
          display: flex;
        }
      }
    }
    width: 100%;
    padding: 1rem;
    background-color: color.$NEUTRAL-10;
    border-radius: 16px;
    @include mixin.flexCol(14px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.133);

    .labelHeader {
      @include mixin.flexBetween();
      width: 100%;
      align-items: center;
      .selectDate {
        @include mixin.flexRow(4px);
        border: 2px solid color.$PRIMARY-MAIN;
        border-radius: 8px;
        padding: 4px 8px;
        align-items: center;
        cursor: pointer;
        span {
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
    .table {
      &.tableRadius {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border: 1px solid color.$NEUTRAL-30;
      }
      border: 1px solid color.$NEUTRAL-30;
      border-top: none;
      border-bottom: none;

      overflow: hidden;
      height: auto;

      .progress {
        @include mixin.flexRow(12px);
        align-items: center;
        width: 100%;
        .loadProgress {
          width: 90%;
          height: 12px;
          background-color: color.$NEUTRAL-20;
          border-radius: 12px;
          @include mixin.flexRow(0);
          position: relative;
          > div {
            background: var(
              --Color-Gradient-1,
              linear-gradient(
                94deg,
                #274551 0.22%,
                #203a43 49.5%,
                #2c5364 99.78%
              )
            );
            position: absolute;
            height: 100%;
            border-radius: 12px;
          }
        }
        span {
          width: 50px;
          text-align: center;
          color: color.$NEUTRAL-100;
          font-family: variables.$BASE-FONT-FAMILY;
          font-size: 14px;
        }
      }
    }
  }
  .tableTwoRow {
    @include mixin.flexRow(20px);
    padding-bottom: 32px;

    > div:nth-child(1),
    > div:nth-child(2) {
      width: 100%;
      border-radius: 16px;
      @include mixin.flexCol(14px);
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.133);
      .labelHeader {
        @include mixin.flexBetween();
        width: 100%;
        align-items: center;
        padding: 1rem 1rem 0px 1rem;
        .refresh {
          cursor: pointer;
          padding: 4px;
          border: 2px solid color.$PRIMARY-MAIN;
          border-radius: 8px;
          @include mixin.flexRow(0);
          align-items: center;
        }
      }
    }
    .table {
      border-radius: 16px;

      .flexEnd {
        @include mixin.flexRow(0);
        justify-content: flex-end;
        .condition {
          width: 84px;
          border-radius: 20px;
          background-color: color.$SUCCESS-MAIN;
          text-align: center;
          font-weight: 500;
          padding-block: 2px;
          font-size: 12px;
          &[condition="rusak"] {
            background-color: #f9f6d3;
            color: #e78b00;
          }
          &[condition="baik"] {
            background-color: #ceead3;
            color: color.$SUCCESS-MAIN;
          }
          &[condition="hilang"] {
            background-color: #f5ddda;
            color: color.$DANGER-MAIN;
          }
        }
      }
      .withPhoto {
        @include mixin.flexRow(4px);
        align-items: center;
        font-size: 14px;
        > img {
          border-radius: 4px;
          object-fit: contain;
        }
      }
    }
  }
  .wrapModalActivities {
    transition: 0.6s ease;
    &[is-open="true"] {
      position: absolute;
      right: 0;
    }
    background-color: color.$NEUTRAL-10;
    width: 661px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    right: -50%;
    .headerModal {
      width: 100%;
      @include mixin.flexBetween();
      border-bottom: 1px solid color.$NEUTRAL-30;
      padding-bottom: 6px;
      padding: 1rem;
      position: sticky;
      top: 0;
      background-color: color.$NEUTRAL-10;
      border-top-left-radius: 8px;

      span {
        color: color.$NEUTRAL-100;
        font-size: 16px;
        font-weight: 500;
      }
      .icon {
        color: color.$NEUTRAL-100;
        cursor: pointer;
      }
    }
    .mainContentModal {
      @include mixin.flexCol(1rem);
      padding: 1rem;
      border-top-left-radius: 8px;

      .status {
        @include mixin.flexCol(4px);
        span {
          font-size: 14px;
          font-weight: 500;
          color: color.$NEUTRAL-70;
        }
        p {
          color: color.$NEUTRAL-100;
        }
        > img[alt="image-location"] {
          width: 100%;
          height: 253px;
          border-radius: 16px;
          object-fit: cover;
        }
      }
    }
  }
}
