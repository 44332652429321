@import "../../../theme/variables.scss";
.wrapperDateListTable {
  .wrapperText {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        font-size: 12px;
        color: $NEUTRAL-100;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperDateListTable {
    .wrapperText {
      line-height: normal;
    }
  }
}
