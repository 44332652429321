@import "../../../theme/variables.scss";
@import "../../../theme/global.scss";

.viewModalChangeClient {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  @include flexCol(8px);
  width: 550px;
  padding: 1rem;
  .header {
    @include flexBetween();
    margin-bottom: calc(1rem - 8px);
    > div {
      @include flexRow(8px);
      align-items: center;
      > span {
        color: black;
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .closeIcon {
      cursor: pointer;
      color: $NEUTRAL-100;
      flex-shrink: 0;
    }
  }
  .description {
    color: $NEUTRAL-90;
    font-size: 14px;
  }
  .tableRadius {
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    overflow: hidden;
  }
  .saveButton {
    @include flexRow(0);
    justify-content: flex-end;
    margin-top: calc(1rem - 8px);
  }
  .loadClient {
    @include flexCol(1rem);
    align-items: center;
    > div {
      @include flexCol(4px);
      align-items: center;
      > p {
        font-size: 12px;
        color: $NEUTRAL-100;
      }
      > div {
        @include flexRow(4px);
        align-items: center;
        font-weight: 600;
        color: $PRIMARY-MAIN;
      }
    }
  }
}

.warpperTopBar {
  width: 100%;
  height: 56px;
  background-color: $NEUTRAL-10;
  box-shadow: 0 2px 10px $NEUTRAL-30;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  padding: 18px;
  display: flex;
  align-items: center;
  .topBarBetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .hamburgerMenu {
      width: 24px;
      height: 24px;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
    }
    .wrapperIconTopBar {
      display: flex;
      gap: 20px;
      align-items: center;
      div {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        .iconTopBar {
          color: $NEUTRAL-100;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
      }
      .profileTopBar {
        display: flex;
        gap: 12px;
        align-items: center;
        div {
          img {
            border-radius: 50%;
          }
          .iconArrowProfile {
            color: $NEUTRAL-100;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            transition: 0.1s ease-out;
          }
          .iconArrowProfile[is_open="true"] {
            transform: rotate(180deg);
          }
        }
      }
      .dropdownChangeClient {
        position: relative;
        width: 100%;
        display: flex;
        gap: 0;
        .changeClient {
          padding: 8px 12px;
          border: 1px solid $NEUTRAL-30;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: $PRIMARY-SURFACE;
          cursor: pointer;
          border-left: none;
          > .edit {
            flex-shrink: 0;
          }
        }
        .select {
          @include flexRow(1rem);
          border: 1px solid $NEUTRAL-30;
          // border-radius: 8px;
          background-color: $NEUTRAL-10;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding: 8px 12px;
          align-items: center;
          transition: 0.2s;
          width: max-content;
          position: relative;
          &:hover {
            background-color: $PRIMARY-SURFACE;
          }
          > div {
            @include flexRow(8px);
            font-size: 12px;
            align-items: center;
            color: $PRIMARY-MAIN;
            width: 100%;
            span {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              overflow: hidden;
              line-clamp: 1;
              -webkit-line-clamp: 1;
            }
          }
          .arrow {
            cursor: pointer;
            transition: 0.2s;
          }
          .arrow[is_open="true"] {
            transform: rotate(180deg);
          }
          // &::after {
          //   content: "";
          //   width: 1px;
          //   height: 100%;
          //   position: absolute;
          //   background-color: $NEUTRAL-30;
          //   right: 2.3rem;
          // }
        }
      }
      .profileTopBar {
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;
        position: relative;

        > div {
          > img {
            border-radius: 50%;
          }

          .iconArrowProfile {
            color: $NEUTRAL-100;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            transition: transform 0.2s ease-out;
          }

          .iconArrowProfile[is-open="true"] {
            transform: rotate(180deg);
          }
        }

        .profileOptions {
          position: absolute;
          top: calc(100% + 4px);
          background-color: $NEUTRAL-10;
          box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
            0px 12px 42px -4px rgba(24, 39, 75, 0.12);
          padding: 12px;
          right: 0;
          border-radius: 8px;
          width: 230px;
          display: flex;
          flex-direction: column;
          cursor: auto;
          gap: 16px;

          > * {
            width: 100%;

            &.top {
              display: flex;
              align-items: center;
              gap: 8px;

              > img {
                width: 40px;
                aspect-ratio: 1;
                border-radius: 50%;
              }

              > div {
                display: flex;
                flex-direction: column;
                gap: 2px;

                > span {
                  font-size: 14px;
                  color: $NEUTRAL-100;
                }

                > p {
                  font-size: 10px;
                  color: $NEUTRAL-60;
                }
              }
            }

            &.divider {
              height: 1px;
              background-color: $NEUTRAL-40;
            }

            &.bottom {
              display: flex;
              flex-direction: column;
              gap: 16px;

              > * {
                color: $NEUTRAL-60;
                cursor: pointer;
                font-size: 16px;

                &:hover {
                  color: $PRIMARY-MAIN;
                }

                &.hide {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .wrapperBreadcrumbs {
      position: absolute;
      top: 0;
      z-index: 99;
      div {
        display: flex;
        gap: 8px;
        align-items: center;

        .text {
          color: $NEUTRAL-60;
          font-size: 14px;
        }
        .text[is-active="true"] {
          color: $PRIMARY-MAIN;
        }
      }
    }
  }
}
