@use "theme/mixin";
@use "theme/color";

.wrapEmptyHandler {
  @include mixin.flexCol(10px);
  width: 100%;
  // height: calc(100vh - (calc(56px + 140px)));
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  > img {
    width: 257.34px;
    height: 191.75px;
  }
  > div {
    @include mixin.flexCol(4px);
    align-items: center;
    strong {
      color: color.$NEUTRAL-100;
      font-size: 24px;
      font-weight: 500;
    }
    span {
      color: color.$NEUTRAL-60;
      font-size: 14px;
    }
  }
}
