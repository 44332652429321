@import "../../../theme/variables.scss";
.wrapperCheckbox {
  border: 1px solid $NEUTRAL-50;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}
.wrapperCheckbox[isChecked="true"] {
  background-color: $PRIMARY-MAIN;
  border-color: transparent;
}
.wrapperCheckbox[isdisabled="true"] {
  cursor: not-allowed;
  background-color: $NEUTRAL-20;
}
