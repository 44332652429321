@import "../../../theme/variables.scss";

.wrapperTooltip {
  position: relative;
  width: fit-content;
  cursor: pointer;
  .target {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
  }
  &:hover .toolTip {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    // transition-delay: 0.3s;
  }
  .toolTip {
    position: absolute;
    display: flex;
    top: -20px;
    left: calc(100% + 1.5rem);
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    background-color: $PRIMARY-MAIN;
    color: $NEUTRAL-10;
    padding: 12px 16px;
    border-radius: 8px;
    transition: visibility 0s, opacity 0.3s, transform 0.3s;
    z-index: 2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    box-shadow: 0 10px 12px #2e319246;
    white-space: nowrap;

    .popover {
      position: absolute;
      left: -16px;
      z-index: -1;
      flex-shrink: 0;
      width: 16px;
      height: 28px;
      color: $PRIMARY-MAIN;
    }
  }
}
