@import "../../../theme/variables.scss";

.thumbnailWrapper {
    border-radius: 8px;
    object-fit: cover;
    width: var(--thumbnail-width);
    height: var(--thumbnail-height);
}

.errorIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 12px;
    background-color: $NEUTRAL-30;
    width: var(--thumbnail-width);
    height: var(--thumbnail-height);
}