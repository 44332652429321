@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.wrapperListPatrolPoint {
  width: 100%;
  max-width: 1440px;
  height: calc(100vh - 56px);
  overflow: hidden;
  .mainContainListPatrolPoint {
    height: calc(100vh - 56px);
    overflow: hidden;
    @include mixin.flexCol(1rem);
    justify-content: flex-start;
    position: relative;
    .between {
      @include mixin.flexBetween();
      align-items: center;
      padding: 20px;
      padding-bottom: 0;
    }
    .back {
      @include mixin.flexRow(12px);
      justify-content: flex-start;
      align-items: center;
      > .backIcon {
        color: color.$NEUTRAL-100;
        cursor: pointer;
      }
      > span {
        color: color.$NEUTRAL-100;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .viewFilter {
      width: 100%;
      @include mixin.flexBetween();
      align-items: center;
      padding-inline: 20px;
    }
    .cardLocation {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
      gap: 1rem;
      padding: 20px;
      padding-top: 4px;
      .card {
        display: flex;
        //   width: 216px;
        height: 328px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: color.$NEUTRAL-10;
        box-shadow: 0 2px 10px #3c3c3c31;
        transition: 0.3s ease;
        border-radius: 12px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;
        &:hover {
          box-shadow: none;
          border-radius: 12px !important;
        }
        .img {
          display: flex;
          height: 192px;
          > img[alt="image-location"] {
            width: 100%;
            height: 192px;
            object-fit: cover;
          }
        }
        .description {
          width: 100%;
          padding: 1rem;
          @include mixin.flexCol(12px);
          align-items: flex-start;
          height: 40%;
          .personil {
            @include mixin.flexCol(4px);
            > strong {
              color: color.$NEUTRAL-100;
              font-size: 14px;
              font-weight: 500;
            }
            div {
              @include mixin.flexRow(2px);
              color: color.$NEUTRAL-70;
              align-items: center;
              .peopleIcon {
                height: 16px;
                width: 16px;
                flex-shrink: 0;
              }
              span {
                font-size: 14px;
              }
            }
          }
          .btnCard {
            @include mixin.flexRow(4px);
            align-items: center;
            color: color.$PRIMARY-MAIN;
            cursor: pointer;
            span {
              font-size: 14px;
              font-weight: 500;
            }
            .arrowIcon {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              rotate: 180deg;
            }
          }
        }
        .detailQr {
          @include mixin.flexBetween();
          width: 100%;
          padding: 12px 1rem;
          background-color: color.$PRIMARY-SURFACE;
          cursor: pointer;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;

          > span {
            color: color.$PRIMARY-MAIN;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .card.addBtnCard {
        cursor: pointer;
        border: 1px dashed color.$NEUTRAL-50;
      }
    }
  }
}
