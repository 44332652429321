@use "theme/color";
@use "theme/variables";
@use "theme/mixin";

.containerModalAddSchemaAbsence {
  transition: 0.4s ease-out;
  width: 661px;
  height: 768px;
  height: 99%;
  background-color: color.$NEUTRAL-10;
  border-radius: 8px;
  @include mixin.flexCol(0);
  position: absolute;
  right: -50%;
  justify-content: space-between;
  transition: right 0.4s ease-in-out;
  &.right {
    right: 0 !important;
  }

  .headerModal {
    border-top-left-radius: 8px;
    padding: 1rem;
    @include mixin.flexRow(1rem);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid color.$NEUTRAL-30;
    position: sticky;
    top: 0;
    background-color: color.$NEUTRAL-10;
    > strong {
      color: color.$NEUTRAL-100;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    > .closeCircle {
      cursor: pointer;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }

  .inputField {
    padding: 1rem;
    @include mixin.flexCol(1rem);
    overflow-y: auto;
    height: 100%;
    width: 100%;
    .viewRowInput {
      @include mixin.flexCol(8px);
      label {
        color: color.$NEUTRAL-100;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        span {
          color: color.$DANGER-MAIN;
        }
      }
      .radio {
        align-items: center;
        @include mixin.flexRow(8px);

        > div {
          border: 1px solid color.$NEUTRAL-40;
          width: 100%;
          border-radius: 8px;
          padding: 12px;
          transition: 0.3s;
          &:hover {
            border-color: color.$PRIMARY-HOVER;
          }
          .radioButton {
            @include mixin.flexRow(6px);
            align-items: center;
            span {
              color: color.$NEUTRAL-100;
              font-family: Roboto;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .selectTime {
      @include mixin.flexCol(8px);
      label {
        color: color.$NEUTRAL-100;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        span {
          color: color.$DANGER-MAIN;
        }
      }
      > div {
        @include mixin.flexRow(8px);
      }
    }
  }

  .footerModal {
    background-color: color.$NEUTRAL-10;
    @include mixin.flexRow(0);
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-inline: 1rem;
    position: sticky;
    bottom: 0;
    padding: 1rem;
    border-top: 1px solid color.$NEUTRAL-30;
    border-bottom-left-radius: 8px;
    &.between {
      justify-content: space-between !important;
      .trashIcon {
        cursor: pointer;
        flex-shrink: 0;
        color: color.$DANGER-MAIN;
      }
    }
  }
}
.modalAddWorkLocation[is_filled="true"] {
  position: absolute;
  right: -50%;
}
.modalAddWorkLocation[is_transition="true"] {
  right: 0% !important;
}
