@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.fullWidth {
    width: 100vw;
    height: 100vh;  
  }

  >img {
    height: 403px;
    aspect-ratio: 1;
    object-fit: cover;
  }

  >h3 {
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    padding-bottom: 8px;
  }

  >p {
    width: 403px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;
    padding-bottom: 24px;
  }

  >button {
    padding: 10px 12px;
    border-radius: 8px;
    border: none;
    background-color: $PRIMARY_MAIN;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $NEUTRAL_10;
  }
}

@media screen and (max-width: 768px) {
  .container {

    >* {
      max-width: 90vw;
    }
  }
}