@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  min-width: 450px;
  max-width: 100vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    >h3 {
      font-size: 16px;
      line-height: 150%;
      font-weight: 600;
      flex-grow: 1;
    }

    >button {
      display: grid;
      place-items: center;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }

  .content {
    // display: flex;
    // align-items: center;
    gap: 16px;
    display: grid;


    &.attend,
    &.permit,
    &.sick {
      grid-template-columns: 1fr 1fr;
    }

    .selfie {
      background-color: #323639;
      width: 417px;
      height: 385px;
      max-width: calc(100vw - 50px);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: clip;
      position: relative;

      >div {
        width: 100%;
      }


      >img {
        max-width: 100%;
        object-fit: contain;
        max-height: calc(100% - 35px);
        flex-grow: 1;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

      .scaler {
        background-color: #000;
        height: 35px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: $NEUTRAL-10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        >span {
          font-weight: 600;
        }

        >button {
          color: $NEUTRAL-10;
          font-size: 16px;
          font-weight: 600;
          padding: 2px 4px;

          &:disabled {
            color: $NEUTRAL-100;
          }
        }
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      overflow: auto;

      .tabs {
        display: grid;
        grid-auto-flow: column;
        gap: 24px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $NEUTRAL-10;

        .tab {
          padding-block: 13px;
          font-size: 14px;
          color: $NEUTRAL-50;
          border-bottom: 1px solid $NEUTRAL-30;
          cursor: pointer;

          &.active {
            color: $PRIMARY-MAIN;
            box-shadow: 0px -2px 0px 0px #2C5364 inset;
          }
        }
      }

      .detailContent {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px 12px;
          border: 1px solid $NEUTRAL-30;
          border-radius: 8px;

          >div {
            display: flex;
            align-items: center;
            gap: 4px;

            >span {
              font-size: 14px;
              line-height: 142.875%;
              color: $NEUTRAL-90;
              flex-grow: 1;
            }

            >p {
              font-size: 14px;
              line-height: 142.875%;
              color: $NEUTRAL-90;
              font-weight: 500;
            }
          }
        }

        .location {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .lHeader {
            display: flex;
            align-items: center;
            gap: 8px;

            >span {
              font-size: 14px;
              font-weight: 400;
              line-height: 142.875%;
              color: $NEUTRAL-70;
              flex-grow: 1;
            }

            >p {
              font-size: 14px;
              font-weight: 500;
              line-height: 142.875%;
              color: $NEUTRAL-100;
            }
          }

          .mapWrapper {
            background-color: $NEUTRAL-100;
            flex-grow: 1;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            overflow: clip;

            >* {
              height: 100%;
            }
          }
        }

        .absenceWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          >div {
            display: flex;
            flex-direction: column;
            gap: 4px;


            >span {
              font-size: 14px;
              line-height: 142.857%;
              color: $NEUTRAL-70;
            }

            >div {
              display: flex;
              align-items: center;
              gap: 4px;

              >span {
                font-size: 14px;
                line-height: 142.875%;
                color: $NEUTRAL-100;
              }
            }
          }
        }
      }

      .equipmentContent {
        display: flex;
        flex-direction: column;
        gap: 12px;

        >p {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          color: $NEUTRAL-70;
        }

        >div {
          display: flex;
          flex-direction: column;
          gap: 8px;

          >div {
            display: flex;
            align-items: center;
            gap: 8px;

            >span {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              /* 142.857% */
            }
          }

          >p {
            font-size: 12px;
            color: $NEUTRAL-60;
          }
        }
      }

      .reportContent {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 1px;
        flex-grow: 1;
        overflow: auto;


        .inventory {
          padding-inline: 16px;
          border: 1px solid $NEUTRAL-30;
          border-radius: 12px;
          display: flex;
          flex-direction: column;

          .inventoryHeader {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            padding-block: 16px;

            >span {
              flex-grow: 1;
              font-size: 14px;
              font-weight: 600;
              color: $NEUTRAL-100;
            }

            >button {
              display: grid;
              place-items: center;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }

          .inventoryContent {
            padding-block: 16px;
            border-top: 1px solid $NEUTRAL-30;
            display: flex;
            flex-direction: column;
            gap: 12px;

            >div {
              padding: 8px;
              border: 1px solid $NEUTRAL-30;
              border-radius: 8px;

              &.users {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                gap: 10px;

                >div {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  >img {
                    width: 20px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                  }

                  >span {
                    font-size: 14px;
                    font-weight: 500;
                    overflow: hidden;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    word-break: break-all;


                  }
                }
              }

              &.items {
                display: flex;
                flex-direction: column;

                .itemsTitle {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid $NEUTRAL-30;

                  >span {
                    font-size: 14px;
                    font-weight: 600;
                  }
                }

                >p {
                  padding-top: 8px;
                  font-size: 12px;
                  color: $NEUTRAL-60;
                }

                .itemWrapper {
                  padding-block: 10px;
                  border-bottom: 1px solid $NEUTRAL-30;
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                  }

                  .item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
  
  
                    >img {
                      width: 32px;
                      aspect-ratio: 1;
                      object-fit: cover;
                      border-radius: 4px;
                    }
  
                    .itemDescription {
                      display: flex;
                      flex-direction: column;
                      flex-grow: 1;
  
                      >span {
                        font-size: 14px;
                        font-weight: 600;
                        color: $NEUTRAL-100;
                      }
  
                      >p {
                        font-size: 12px;
                        color: $NEUTRAL-90;
                      }
                    }

                    >button {
                      border: 1px solid $NEUTRAL-100;
                      height: 40px;
                      width: 40px;
                      display: grid;
                      place-items: center;
                      border-radius: 5px;

                      &.success {
                        border-color: $SUCCESS-MAIN;
                        color: $SUCCESS-MAIN;
                      }
                      &.danger {
                        border-color: $DANGER-MAIN;
                        color: $DANGER-MAIN;
                      }
                    }
                  }

                  .notes {
                    padding: 8px;
                    background-color: $PRIMARY-SURFACE;
                    border-radius: 8px;

                    >span {
                      color: $PRIMARY-MAIN;
                      font-size: 12px;
                      font-weight: 600;
                    }

                    >p {
                      color: $NEUTRAL-100;
                      font-size: 12px;
                    }
                  }

                }

              
              }
            }


          }
        }
      }


    }
  }
}