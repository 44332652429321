@import "../../../theme/variables.scss";

.container {
  padding: 6px 12px;
  border: 1px solid $NEUTRAL-40;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background-color: $NEUTRAL-10;

  > span {
    flex-grow: 1;
    // font-family: $BASE-FONT-FAMILY;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }

  .rotate {
    transition: 0.3s;
    transform: rotate(180deg);
  }

  .optionsWrapper {
    position: absolute;
    width: 100%;
    left: calc(100% + 8px);
    bottom: -8px;
    border-radius: 0 8px 8px 0;
    // top: calc(100% + 10px);
    z-index: 1;
    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
      0px 8px 18px -6px rgba(24, 39, 75, 0.12);
    background-color: $NEUTRAL-10;
    padding-inline: 8px;

    > div {
      // padding: 2px 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL-30;
      }

      &:hover {
        background-color: $NEUTRAL-20;
        font-weight: 500;
      }
    }

    // top: calc(100% + 20px);
  }
}
