@import "../../../theme/variables.scss";
.wrapperEmptyFamily {
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 207px;
    height: 135px;
  }
  .description {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    text-align: center;
    color: $NEUTRAL-100;
    max-width: 412px;
    width: 100%;
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: normal;
      }
    }
    p {
      font-size: 14px;
    }
  }
}
