$NEUTRAL-0: #e5e5e5;
$NEUTRAL-10: #ffffff;
$NEUTRAL-20: #edeff2;
$NEUTRAL-30: #dee2e7;
$NEUTRAL-40: #cdd2db;
$NEUTRAL-50: #bbc3ce;
$NEUTRAL-60: #a9b3c1;
$NEUTRAL-70: #919eb0;
$NEUTRAL-80: #718198;
$NEUTRAL-90: #556377;
$NEUTRAL-100: #3e4856;

$PRIMARY-MAIN: #2c5364;
$PRIMARY-SURFACE: #e6f0f4;
$PRIMARY-HOVER: #244451;
$PRIMARY-PRESSED: #112027;
$PRIMARY-BACKGROUND: rgba(212, 212, 232, 0.1);

$DANGER-MAIN: #d42701;
$DANGER-SURFACE: rgba(240, 44, 1, 0.2);
$DANGER-OUTLINE: #fa2d00;
$DANGER-BASE: #f5610d;

$ALERT-MAIN: #eed202;
$ALERT-SURFACE: #fffce6;
$ALERT-OUTLINE: #feeb5d;

$SUCCESS-MAIN: #22970f;
$SUCCESS-SURFACE: #90cb87;
$SUCCESS-OUTLINE: #f4fff2;

$STROKE: #d9d9d9;

$SHADOW-100: 0px 4px 4px -2px rgba(24, 39, 75, 0.08),
  0px 2px 4px -2px rgba(24, 39, 75, 0.12);
$SHADOW-300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
  0px 8px 16px -6px rgba(24, 39, 75, 0.08);
$SHADOW-500: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
  0px 10px 32px -4px rgba(24, 39, 75, 0.10);
$SHADOW-600: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
  0px 12px 42px -4px rgba(24, 39, 75, 0.12);
$SHADOW-700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
  0px 14px 64px -4px rgba(24, 39, 75, 0.12);

$BASE-FONT-FAMILY: "Roboto", sans-serif;

@mixin width($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-size($max, $min, $minpx) {
  font-size: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-weight($max, $min, $minpx) {
  font-weight: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin line-height($max, $min, $minpx) {
  line-height: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin fluid-image-size($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
  height: 100%;
}

@mixin top($max, $min, $minpx) {
  top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin gap($max, $min, $minpx) {
  gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding($max, $min, $minpx) {
  padding: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-top($max, $min, $minpx) {
  padding-top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-right($max, $min, $minpx) {
  padding-right: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-bottom($max, $min, $minpx) {
  padding-bottom: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-left($max, $min, $minpx) {
  padding-left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-block($max, $min, $minpx) {
  padding-block: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-inline($max, $min, $minpx) {
  padding-inline: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}
