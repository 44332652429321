@use "../../../theme/color" as c;


.container {
  background: rgba(212, 212, 232, 0.10);
  background-image: url("../../../assets/Images/bg-permata-security.png");
  height: 100vh;
  overflow: auto;
  background-size: cover;
  background-position: 10% 53%;
  background-repeat: no-repeat;
  padding-block: 36px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .card {
    background-color: c.$NEUTRAL-10;
    width: 563px;
    border-radius: 8px;
    box-shadow: c.$SHADOW-300;
    overflow: auto;

    .header {
      padding: 16px;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid c.$NEUTRAL-20;
      position: sticky;
      top: 0;
      background-color: c.$NEUTRAL-10;
      z-index: 1;

      .mws {
        width: 32px;
        object-fit: contain;
      }

      >h3 {
        flex-grow: 1;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      .sec {
        width: 37px;
        object-fit: contain;
      }
    }

    .content {
      padding: 16px;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .audios {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

}

.description {

  >span {
    font-size: 14px;
    color: c.$NEUTRAL-70;
  }

  >p {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: flex-start;
    padding-inline: 8px;
    padding-block: 16px;
    display: block;

    .card {
      max-width: calc(100vw - 16px);
    }
  }
}