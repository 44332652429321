@import "../../../../../theme/global.scss";

.containerSkeletonListEmployee {
  @include flexRow(0);
  width: 100%;
  justify-content: center;
}
.wrapperSkeletonListEmployee {
  max-width: 1440px;
  width: 100%;
  @include flexCol(1rem);
  padding: 20px;
  .viewRow {
    @include flexRow(1rem);
  }
  .viewRowBetween {
    @include flexRow(1rem);
    width: 100%;
    justify-content: space-between;
    .viewRow {
      @include flexRow(1rem);
    }
  }
}
