@use "theme/mixin";
.viewerSkeletonDetailInfoLocation {
  max-width: 1440px;
  padding: 20px;
  width: 100%;
  @include mixin.flexCol(20px);
  .between {
    @include mixin.flexBetween();
    align-items: center;
  }
  .spaceAround {
    @include mixin.flexRow(1rem);
  }
  .viewRow {
    @include mixin.flexRow(1rem);
  }
}
