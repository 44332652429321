@import "../../../theme/variables.scss";

.containerEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 100%;
  padding: 20px;
  img {
    width: 254.686px;
    height: 165.06px;
  }
  > h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.1px;
  }

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;
  }
}
