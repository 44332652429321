@import "../../../theme/global.scss";
@import "../../../theme/variables.scss";

@keyframes fadeOut {
  0% {
    background-color: $NEUTRAL-40;
  }
  50% {
    background-color: $NEUTRAL-30;
  }
  100% {
    background-color: $NEUTRAL-20;
  }
}

.wrapperLoadSkeleton {
  width: 100%;
  aspect-ratio: 10/2;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
  border-radius: 4px;
  &.true {
    background: linear-gradient(
      90deg,
      $NEUTRAL-30 25%,
      $NEUTRAL-50 60%,
      $NEUTRAL-30 75%
    );
    background-size: 200% 100%;
    // animation: tsunamiAnimate 1.5s infinite cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: tsunamiAnimate 1.4s infinite linear;
  }
}

.circleSkeleton {
  aspect-ratio: 1/1;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate-reverse;
  border-radius: 50%;
}

.boxSkeleton {
  aspect-ratio: 1/1;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s linear alternate-reverse;
  border-radius: 4px;
  &.true {
    background: linear-gradient(
      90deg,
      $NEUTRAL-20 25%,
      $NEUTRAL-30 60%,
      $NEUTRAL-20 75%
    );
    background-size: 200% 100%;
    // animation: tsunamiAnimate 1.5s infinite cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: tsunamiAnimate 1.8s infinite linear;
  }
}

@keyframes tsunamiAnimate {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
