@use "theme/color";
@use "theme/mixin";

.maxCenteringContain {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .wrapperListWorkSchedule {
    width: 100%;
    padding: 20px;
    max-width: 1440px;
    @include mixin.flexCol(1rem);
    .header {
      @include mixin.flexBetween();
      align-items: center;
      > div {
        @include mixin.flexRow(12px);
        align-items: center;

        .back {
          cursor: pointer;
          height: 20px;
          width: 20px;
          color: color.$NEUTRAL-100;
        }
        > h3 {
          color: color.$NEUTRAL-100;
          font-size: 24px;
          font-weight: 500;
        }
      }
      .selectedDate {
        @include mixin.flexRow(10px);
        align-items: center;
        border: 1px solid color.$NEUTRAL-40;
        padding: 10px;
        border-radius: 8px;
        position: relative;
        transition: 0.2s ease-out;
        .settings {
          cursor: pointer;
          height: 16px;
          width: 16px;
          flex-shrink: 0;
          color: color.$NEUTRAL-90;
        }
        span {
          color: color.$NEUTRAL-100;
          font-size: 14px;
        }
        .wrapFilterYear {
          position: absolute;
          z-index: 99;
          right: 0;
          top: 2.5rem;
          width: 154px;
          height: auto;
          background-color: color.$NEUTRAL-10;
          border-radius: 8px;
          box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
            0px 14px 64px -4px rgba(24, 39, 75, 0.12);
          @include mixin.flexCol(0);
          overflow: hidden;
          > span {
            padding: 6px 8px;
            transition: 0.2s ease-out;
            cursor: pointer;
            color: color.$PRIMARY-MAIN;
            font-size: 12px;
            font-weight: 500;
            &:hover {
              background-color: color.$PRIMARY-SURFACE;
            }
          }
          > span[is_active="true"] {
            background-color: color.$PRIMARY-MAIN;
            color: #fff;
          }
        }
      }
      .selectedDate[is_open="true"] {
        border-color: color.$PRIMARY-MAIN;
      }
    }
    .tableRadius {
      width: 100%;
      border-radius: 8px;
      border: 1px solid color.$NEUTRAL-20;
      overflow: hidden;
      .status {
        @include mixin.flexRow(0);
        justify-content: center;
        padding: 4px 8px;
        border-radius: 20px;
        background-color: black;
        &[status="sudah diatur"] {
          background-color: color.$SUCCESS-SURFACE;
          color: color.$SUCCESS-MAIN;
        }
        &[status="belum lengkap"] {
          background-color: color.$PRIMARY-SURFACE;
          color: #2e3192;
        }
        &[status="belum diatur"] {
          background-color: color.$ALERT-SURFACE;
          color: color.$NEUTRAL-100;
        }

        > span {
          font-size: 12px;
          font-weight: 500;
        }
      }
      .centerAlign {
        width: 100%;
        @include mixin.flexRow(0);
        align-items: center;
        justify-content: center;
      }
    }
  }
}
