.container {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}
@import "../../../theme/variables.scss";
.wrapperTabs {
  display: flex;
  gap: 24px;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: max-content;
    > .wrapIcon {
      display: flex;
      gap: 0;
      align-items: center;
      position: relative;
      .icon {
        flex-shrink: 0;
        transition: 0.2s ease-out;
        cursor: pointer;
        &[opened="true"] {
          transform: rotate(180deg);
        }
      }
      .absoluteMenu {
        position: absolute;
        width: 100%;
        height: auto;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.119);
        top: 4rem;
        // padding: 6px 8px;
        display: flex;
        flex-direction: column;
        row-gap: 0;
        z-index: 999;
        > .list {
          color: var(--Primary-Main, #2c5364);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          cursor: pointer;
          padding: 6px 8px;

          &:hover {
            background-color: $PRIMARY-SURFACE;
            &:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
            &:last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
          &[isactive="true"] {
            background-color: $PRIMARY-MAIN;
            color: #fff;
            &:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
            &:last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
    h2 {
      padding: 13px 8px;
      color: $NEUTRAL-50;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: $NEUTRAL-100;
      }
    }
    .tabIndicator {
      height: 1px;
      width: 100%;
      background-color: $NEUTRAL-30;
      &[isaddicon="true"] {
        width: calc(100% - 22px);
      }
    }
  }
  .wrapper[isactive="true"] {
    h2 {
      color: $PRIMARY-MAIN;
    }
    .tabIndicator {
      height: 2px;
      // background-color: #2b6ed3;
      background-color: $PRIMARY-MAIN;
    }
  }
}

@media screen and (max-width: 765px) {
  .wrapperTabs {
    padding: 8px 0px 8px 0px;
    width: 100%;
    overflow-x: scroll;
    align-items: flex-end;
  }
}
