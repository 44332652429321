@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.containerDetailReportActivity {
  @include mixin.flexCol(20px);
  width: 100%;
  justify-content: flex-start;
  max-width: 1440px;
  height: 100%;
  padding: 20px;
  .header {
    @include mixin.flexRow(12px);
    align-items: center;
    justify-content: space-between;

    > div {
      @include mixin.flexRow(12px);
      align-items: center;

      .back {
        cursor: pointer;
        height: 20px;
        width: 20px;
        color: color.$NEUTRAL-100;
      }
      > h3 {
        color: color.$NEUTRAL-100;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .settings {
      cursor: pointer;
      height: 32px;
      width: 32px;
      flex-shrink: 0;
      color: color.$NEUTRAL-60;
    }
  }
  .wrapViewContain {
    height: 92%;
    width: 100%;
    @include mixin.flexRow(20px);
    .left {
      box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
        0px 12px 42px -4px rgba(24, 39, 75, 0.12);
      border-radius: 12px;
    }
    .left {
      width: 300px;
      height: 100%;
      background-color: color.$NEUTRAL-10;
      padding: 1rem;
      @include mixin.flexCol(0);
      flex-shrink: 0;
      overflow-y: auto;
      .divider {
        width: 100%;
        background-color: color.$NEUTRAL-30;
        padding-block: 0.5px;
        margin-block: 12px;
      }
      .selectStatusLocation {
        @include mixin.flexCol(8px);
        color: color.$NEUTRAL-100;
        &:last-child {
          // height: 130px;
          > div {
            .profile {
              align-items: center;
              @include mixin.flexRow(6px);
              > img {
                height: 20px;
                width: 20px;
                border-radius: 50%;
              }
              > div {
                @include mixin.flexCol(0);
                font-weight: 500;
                strong {
                  font-size: 12px;
                  color: color.$NEUTRAL-100;
                }
                span {
                  color: color.$NEUTRAL-70;
                  font-size: 10px;
                }
              }
            }
          }
        }
        > strong {
          font-size: 14px;
          font-weight: 500;
        }
        > div {
          @include mixin.flexRow(8px);
          align-items: center;
          > span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .listPersonil {
        @include mixin.flexCol(8px);
        > strong {
          font-size: 14px;
          color: color.$NEUTRAL-100;
        }
      }
    }
    .left::-webkit-scrollbar {
      width: 6px;
      transition: 0.2s;
    }

    .left::-webkit-scrollbar-thumb {
      background-color: color.$NEUTRAL-40;
      border-radius: 8px;
    }
    .left::-webkit-scrollbar-thumb:hover {
      background-color: color.$NEUTRAL-50;
    }

    .right {
      width: 100%;
      @include mixin.flexCol(12px);
      .wrapSelectedPersonil {
        @include mixin.flexRow(8px);
      }
      .rightContain {
        width: 100%;
        height: 100%;
        background-color: color.$NEUTRAL-10;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
          0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        overflow: auto;
        border-radius: 12px;
        .wrapTabs {
          @include mixin.flexRow(0);
          background: color.$NEUTRAL-10;
          position: sticky;
          top: 0;
          .tabs {
            cursor: pointer;
            width: 100%;
            @include mixin.flexRow(0);
            justify-content: space-around;
            padding-block: 13px;
            border-bottom: 1px solid color.$NEUTRAL-30;
            transition: 0.2s ease;
            font-size: 14px;
            color: color.$NEUTRAL-50;
            &[is-active="true"] {
              border-bottom: 2px solid color.$PRIMARY-MAIN;
              color: color.$PRIMARY-MAIN;
            }
          }
        }
      }
    }
  }
}

.wrapEmptyHandler {
  @include mixin.flexCol(10px);
  width: 100%;
  height: 90%;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  > img {
    width: 257.34px;
    height: 191.75px;
  }
  > div {
    @include mixin.flexCol(4px);
    align-items: center;
    strong {
      color: color.$NEUTRAL-100;
      font-size: 24px;
      font-weight: 500;
    }
    span {
      color: color.$NEUTRAL-60;
      font-size: 14px;
    }
  }
}
