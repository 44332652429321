@use "theme/color";
@use "theme/mixin";

.selectDate {
  width: 284px;
  right: 0;
  position: absolute;
  top: calc(100% + 12px);
  border-radius: 16px;
  padding: 12px;
  background-color: color.$NEUTRAL-10;
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.10);
}

@mixin styleForModalDownload($width: 170px) {
  position: absolute;
  top: 2.5rem;
  background-color: color.$NEUTRAL-10;
  right: 0;
  z-index: 99;
  width: $width;
  border-radius: 8px;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.wrapperBtnDownloadReport {
  @include styleForModalDownload(170px);
  ul {
    width: 100%;
    li {
      list-style: none;
      padding: 6px 8px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: color.$PRIMARY-MAIN;
      transition: 0.2s;
      &:hover {
        background-color: color.$PRIMARY-SURFACE;
      }
    }
  }
}

.datesWrapper {
  @include styleForModalDownload(409px);
  padding: 16px 20px;
  border-radius: 8px;
  @include mixin.flexCol(1rem);
  display: flex;
  flex-direction: column !important;

  .header {
    width: 100%;
    @include mixin.flexBetween();

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      > strong {
        color: color.$NEUTRAL-100;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .closeIcon {
      cursor: pointer;
      flex-shrink: 0;
    }
  }
  .inputField {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
  .btnDownload {
    display: flex;
    width: 100%;
  }
}
