@import "../../../theme/variables.scss";
@import "../../../theme/global.scss";
@keyframes fadeOut {
  0% {
    background-color: $NEUTRAL-40;
  }
  50% {
    background-color: $NEUTRAL-30;
  }
  100% {
    background-color: $NEUTRAL-20;
  }
}
.wrapperInputField {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  width: 100%;
  padding-inline: 1px;
  label {
    color: $NEUTRAL-100;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    gap: 4px;
    .text {
      color: $NEUTRAL-100;
    }
    span {
      color: $DANGER-MAIN;
    }
  }
  .mainInput {
    height: 44px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 8px;
    transition: 0.3s;
    width: 100%;
    position: relative;
    .textField {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $NEUTRAL-40;
      outline: 1px solid transparent;
      font-size: 1rem;
      transition: 0.3s;
      &::placeholder {
        color: $NEUTRAL-50;
      }
      &:focus {
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;
        transition: none;
      }
      &:hover {
        border-color: $PRIMARY-HOVER;
      }
    }
    .textField[isError="true"] {
      border: 1px solid $DANGER-MAIN;
      outline: 1px solid $DANGER-MAIN;
    }
    .textField[isdropdown="true"] {
      display: none;
    }
    .textField[isselectTime="true"] {
      display: none;
    }
    .textField[isDisabled="true"] {
      background-color: $NEUTRAL-20;
      border-color: $NEUTRAL-40;
      cursor: not-allowed;
      &::placeholder {
        color: $NEUTRAL-50;
      }
    }

    .dropdown {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      .wrapper {
        display: flex;
        height: 44px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 8px;
        align-items: center;
        padding: 12px;
        justify-content: space-between;
        cursor: pointer;
        background-color: $NEUTRAL-10;
        span {
          color: $NEUTRAL-50;
        }
        span[ischooseitems="true"] {
          color: $NEUTRAL-100;
        }
        .chevron {
          transition: 0.3s;
          color: $NEUTRAL-40;
          &:hover {
            color: $PRIMARY-HOVER;
          }
        }
        .chevron[isopen="true"] {
          transform: rotate(180deg);
          color: $NEUTRAL-100;
        }
        .chevron[isopen="false"] {
          transform: rotate(0deg);
        }
        &:hover {
          border-color: $PRIMARY-HOVER;
          transition: 0.5s;
        }
        .btnChart {
          padding: 4px 8px;
          border: 2px solid $PRIMARY-MAIN;
          border-radius: 6px;
          display: flex;
          align-items: center;
          span {
            color: $PRIMARY-MAIN;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            span:nth-child(1) {
              font-style: normal;
            }
            span:nth-child(2) {
              font-style: italic;
              font-weight: 600;
            }
          }
        }
      }
      .wrapper[iserror="true"] {
        border: 2px solid $DANGER-MAIN;
      }
      .wrapper[ischooseitems="true"] {
        border: 1px solid $NEUTRAL-30;
        outline: 1px solid $NEUTRAL-30;
        &:hover {
          border-color: $PRIMARY-HOVER;
          transition: 0.5s;
        }
      }
      .wrapper[isleft="true"] {
        justify-content: flex-start;
        gap: 10px;
        span {
          order: 2;
        }
        .chevron {
          order: 1;
        }
      }
      .wrapper[isopen="true"] {
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;

        transition: none;
      }
      .wrapper[isdisabled="true"] {
        cursor: not-allowed;
        background-color: $NEUTRAL-20;
        .chevron {
          color: $NEUTRAL-50;
        }
        &:hover {
          outline-color: $NEUTRAL-30;
        }
      }
      .dropdownItems {
        &[isdropshadow="true"] {
          box-shadow: 0px 4px 10px #00000041;
        }
        margin-top: 5px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -0%);
        position: absolute;
        width: inherit;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;
        background-color: $NEUTRAL-10;
        z-index: 1001 !important;
        box-shadow: 0px 4px 10px $NEUTRAL-30;
        max-height: 218px;
        overflow-y: scroll;
        ul li {
          list-style-type: none;
          border-bottom: 1px solid $NEUTRAL-30;
          padding: 8px 12px;
          cursor: pointer;
          font-size: 1rem;
          &:hover {
            background-color: $NEUTRAL-20;
          }
          // &:last-child {
          //   display: flex;
          //   flex-direction: column;
          // }
        }
        ul li:nth-last-child(1) {
          border: none;
        }
        ul[is-loading="true"] {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          align-items: center;
          padding: 8px 12px;
          li {
            cursor: progress;
            width: 100%;
            height: 32px;
            animation: fadeOut infinite 0.8s ease-in alternate;
            border-radius: 4px;
          }
        }
      }
      .dropdownItems[isOpen="true"] {
        opacity: 1;
        visibility: visible;
      }
    }
    .dropdownAuto {
      @include flexCol(0);
      position: absolute;
      top: calc(50px);
      width: 100%;
      height: auto;
      max-height: 130px;
      background-color: #fff;
      box-shadow: 1px 4px 10px $NEUTRAL-60;
      border-radius: 8px;
      z-index: 999;
      overflow-y: auto;
    }
    .autoComplete {
      display: flex;
      @include flexRow(0);
      justify-content: space-between;
      cursor: pointer;
      padding: 4px 12px;

      &:hover {
        background-color: $PRIMARY-SURFACE;
      }
      .namesAndPhoto {
        @include flexRow(8px);
        align-items: center;
        > img {
          border-radius: 50%;
          width: 14px;
          height: 14px;
          object-fit: contain;
        }
        > span {
          font-size: 14px;
          color: $NEUTRAL-100;
          font-weight: 500;
        }
      }
      > .position {
        font-weight: 500;
        padding: 2px 8px;
        border-radius: 8px;
        background-color: $PRIMARY-SURFACE;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }
  }
  .mainInput[istext-area="true"] {
    height: 65px;
    .textField {
      resize: none;
    }
  }
  .mainInput[is-add-element="true"] {
    position: relative;
    height: 100%;
    &::after {
      content: attr(data_text);
      position: absolute;
      right: 0.5px;
      top: 1px;
      font-size: 14px;
      color: $NEUTRAL-100;
      background-color: $NEUTRAL-30;
      padding: 13.2px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.error {
  font-size: 14px;
  color: $NEUTRAL-100;
  background-color: $NEUTRAL-10;
  box-shadow: 0px 1px 8px $NEUTRAL-30;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  bottom: -43px;
  z-index: 1;
  span {
    font-size: 12px;
  }
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
  }
}
@media (max-width: 768px) {
  .wrapperInputField {
    label {
      .text {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        // max-width: 151px;
      }
    }
    .mainInput {
      .textField {
        &::placeholder {
          font-size: 14px;
        }
      }
      .dropdown {
        .wrapper {
          span {
            font-size: 14px;
            overflow: hidden;
            // display: -webkit-box;
            // line-clamp: 1;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;
            // max-width: 151px;
          }
          .dropdownItems {
            ul li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.mainInput {
  .textField {
    &[for_login_mws="true"] {
      &:focus {
        border: 1px solid #2e3192;
        outline: 1px solid #2e3192;
        transition: none;
      }
      &:hover {
        border-color: #0805d7;
      }
    }
  }
}

// TimeInput.module.scss

.containerSelectTime {
  position: relative;
  width: 100%;

  .wrapper {
    width: 100%;
    display: flex;
    height: 44px;
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
    align-items: center;
    padding: 12px;
    // justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    background-color: $NEUTRAL-10;
    span {
      color: $NEUTRAL-50;
    }
    span[ischooseitems="true"] {
      color: $NEUTRAL-100;
    }
    .chevron {
      transition: 0.3s;
      color: $NEUTRAL-40;
      &:hover {
        color: $PRIMARY-HOVER;
      }
      &.filled {
        color: $PRIMARY-MAIN !important;
      }
    }
    .chevron[isopen="true"] {
      transform: rotate(180deg);
      color: $NEUTRAL-100;
    }
    .chevron[isopen="false"] {
      transform: rotate(0deg);
    }
    &:hover {
      border-color: $PRIMARY-HOVER;
      transition: 0.5s;
    }
    .btnChart {
      padding: 4px 8px;
      border: 2px solid $PRIMARY-MAIN;
      border-radius: 6px;
      display: flex;
      align-items: center;
      span {
        color: $PRIMARY-MAIN;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        span:nth-child(1) {
          font-style: normal;
        }
        span:nth-child(2) {
          font-style: italic;
          font-weight: 600;
        }
      }
    }
  }

  .timeInput {
    display: flex;
    flex-direction: column;
    row-gap: 12px !important;
    .contain {
      display: flex;
      gap: 12px;
      align-items: center;
    }
    position: absolute;
    top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px;
    background-color: $NEUTRAL-10;
    border-radius: 8px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
      0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    width: 100%;
    z-index: 99;
    // opacity: 0;
  }

  .timeField {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    width: 100%;
    .arrowUp {
      transform: rotate(180deg);
      flex-shrink: 0;
    }
    .inputIndicator {
      border-radius: 8px;
      outline: 1px solid transparent;
      height: 44px;
      text-align: center;
      border: 1px solid $NEUTRAL-40;
      font-size: 16px;
      transition: 0.3s;
      width: 100%;
      &:hover {
        border-color: $PRIMARY-HOVER;
      }
      &:focus {
        transition: none;
        border: 1px solid $PRIMARY-HOVER;
        outline: 1px solid $PRIMARY-HOVER;
      }
    }
  }

  .icon {
    cursor: pointer;
    flex-shrink: 0;
  }

  .inputField {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 36px;
    font-size: 18px;
    color: #333;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin: 4px 0;
    background-color: white;
  }

  .colon {
    font-size: 16px;
    font-weight: bold;
    color: $NEUTRAL-100;
    line-height: 24px; /* 150% */
  }

  .ampmToggle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .ampmToggle button {
    width: 100%;
    padding: 4px;
    font-size: 14px;
    color: $NEUTRAL-100;
    background-color: transparent;
    border: none;
    padding: 4px 10px;
    cursor: pointer;

    &.selected {
      background-color: $PRIMARY-SURFACE;
      font-weight: bold;
      color: $PRIMARY-MAIN;
    }
  }

  .okButton {
    margin-top: 8px;
    padding: 8px 16px;
    color: white;
    background-color: #374151;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #4b5563;
    }
  }
}
