@use "theme/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 20px;
  max-width: 1440px;
  margin-inline: auto;

  .header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    >h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 125%;
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 16px;

      .leftFilter {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-grow: 1;
      }

      .rightFilter {
        display: flex;
        align-items: center;
        gap: 12px;

        >* {

          &:first-child {
            width: 170px;
          }

          &:nth-child(2) {
            width: 170px;
          }
        }
      }
    }
  }

  .content {
    border: 1px solid variables.$NEUTRAL-30;
    border-radius: 8px;
    overflow: clip;

    .profile {
      display: flex;
      align-items: center;
      gap: 10px;

      .name {
        display: flex;
        flex-direction: column;

        >span {
          font-size: 14px;
          color: variables.$NEUTRAL-100;
          line-height: 142.857%;
        }

        >p {
          font-size: 10px;
          color: variables.$NEUTRAL-80;
          line-height: 140%;
        }
      }
    }
    
    .time {
      display: flex;
      flex-direction: column;

      >div {
        display: flex;
        align-items: center;
        gap: 4px;

        >span {
          font-size: 12px;
          color: variables.$NEUTRAL-100;
        }
      }
    }

    .totalHours {
      display: flex;
      align-items: center;
      gap: 4px;

      >span {
        font-size: 14px;
        color: variables.$NEUTRAL-100;
      }
    }
    
    .selfie {
      display: flex;
      align-items: center;
      
      >img {
        width: 56px;
        aspect-ratio: 1;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .noSelfie {
      display: flex;
      align-items: center;
      gap: 4px;

      >span {
        font-size: 14px;
        color: variables.$NEUTRAL-100;
      }
    }
  }

}