@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.ModalStatusReport {
  @include mixin.flexCol(0);
  height: 100%;
  background-color: color.$NEUTRAL-10;
  width: 661px;
  border-top-left-radius: 8px;
  position: absolute;
  right: -50%;
  transition: 0.4s ease;
  &[is-open="true"] {
    right: 0;
  }
  .headerModal {
    width: 100%;
    @include mixin.flexBetween();
    border-bottom: 1px solid color.$NEUTRAL-30;
    padding-bottom: 6px;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: color.$NEUTRAL-10;
    border-top-left-radius: 8px;
    align-items: center;
    > div {
      @include mixin.flexRow(8px);
      align-items: center;
      span {
        color: color.$NEUTRAL-100;
        font-size: 16px;
        font-weight: 500;
      }
      .icon {
        color: color.$DANGER-MAIN;
      }
    }
    .icon {
      color: color.$NEUTRAL-100;
      cursor: pointer;
    }
  }

  .mainContainModalStatus {
    width: 100%;
    height: 96%;
    overflow: auto;
    .HistoryActivity {
      @include mixin.flexCol(0);
      background-color: color.$NEUTRAL-10;
      overflow-y: auto;
      &[is-zoom-out="true"] {
        border-right: 1px solid color.$NEUTRAL-30;
        border-left: 1px solid color.$NEUTRAL-30;
        border-radius: 8px;
      }

      .viewerDetail {
        width: 100%;
        padding: 18px 24px;
        @include mixin.flexBetween();
        align-items: center;
        .viewLeft {
          width: 100%;
          @include mixin.flexRow(10px);
          align-items: center;
          .imgProfile {
            padding: 10px;
            border-radius: 50%;
            background-color: color.$NEUTRAL-30;
            @include mixin.flexRow(0);
            align-items: center;
            > img {
              width: 20px;
              height: 20px;
            }
          }
          .names {
            @include mixin.flexCol(0);

            > span {
              color: #000000;
              font-size: 16px;
            }
            > .activities {
              @include mixin.flexRow(0);
              .desc {
                @include mixin.flexRow(8px);
                color: color.$NEUTRAL-60;
                align-items: center;
                > p {
                  font-size: 12px;
                }
              }
            }
            .location {
              @include mixin.flexRow(4px);
              color: color.$PRIMARY-MAIN;
              align-items: center;
              font-size: 12px;
            }
          }
        }
        .viewRight {
          @include mixin.flexRow(10px);
          .condition {
            @include mixin.flexRow(0);
            justify-content: center;
            align-items: center;
            width: 84px;
            padding-block: 2px;
            border-radius: 20px;
            > span {
              font-size: 12px;
              font-weight: 500;
            }
            &[condition="siaga"] {
              background-color: #f9ecd3;
              color: #e78b00;
            }
            &[condition="aman"] {
              background-color: #ceead3;
              color: color.$SUCCESS-MAIN;
            }
            &[condition="waspada"] {
              background-color: #f5ddda;
              color: color.$DANGER-MAIN;
            }
          }
          .iconMax {
            cursor: pointer;
            height: 20px;
            width: 20px;
            color: color.$PRIMARY-MAIN;
          }
        }
      }
    }
    .mainContentModal {
      @include mixin.flexCol(1rem);
      padding: 1rem;
      border-top-left-radius: 8px;

      .status {
        @include mixin.flexCol(4px);
        span {
          font-size: 14px;
          font-weight: 500;
          color: color.$NEUTRAL-70;
        }
        p {
          color: color.$NEUTRAL-100;
        }
        > img[alt="image-location"] {
          width: 100%;
          height: 253px;
          border-radius: 16px;
          object-fit: cover;
        }
      }
    }
  }
}
