@use "theme/color";
@use "theme/mixin";

.viewerSkeletonCards {
  @include mixin.flexCol(1rem);
  padding: 20px;
  width: 100%;
  max-width: 1440px;
  > .between {
    @include mixin.flexBetween();
  }
  > .wrapCards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
    gap: 1rem;
  }
}
