@import "../../../theme/variables.scss";
@keyframes toBottom {
  0%,
  50% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(50px);
    opacity: 1;
  }
}
.wrapperToast {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background-color: $SUCCESS-MAIN;
  width: max-content;
  border-radius: 4px;
  position: fixed;

  transform: scale(0);
  transition: transform 0.5s;
  transform-origin: top;
  top: 5%;
  animation: toBottom 0.5s;
  transform: translateY(-18px);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.388);
  justify-content: center;
  .mainToast {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 12px;
    div {
      align-items: center;
      display: flex;
      gap: 12px;
      span {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL-10;
      }
      .containerIcon {
        display: flex;
        width: 28px;
        height: 28px;
        padding: 4px;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.24);
      }
    }
    .iconX {
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}
.wrapperToast[is_open="true"] {
  visibility: visible;
  opacity: 1;
  transform-origin: top;
  animation: toBottom 0.5s linear ease-in;
  transform: translateY(18px);
}
.wrapperToast[color="green"] {
  background-color: $SUCCESS-MAIN;
}
.wrapperToast[color="yellow"] {
  background-color: $ALERT-MAIN;
}
.wrapperToast[color="red"] {
  background-color: $DANGER-MAIN;
}
.wrapperToast[color="blue"] {
  background-color: $PRIMARY-MAIN;
}
@media (max-width: 768px) {
  .wrapperToast {
    padding: 8px;
    width: 90%;
    position: fixed;
  }
}
