@import "../../../theme/variables.scss";

.container{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    .cardBox{
        display: flex;
        justify-content: space-between;
        padding: 16px;
        width: 100%;
        gap: 10px;
        border-radius: 8px;
        align-items: center;
        border-bottom: 1px solid $NEUTRAL-30;

        .cardWrapper{
            display: flex;
            gap: 10px;
            align-items: center;

            .iconWrapper{
                display: flex;
                padding: 8px;
                gap: 10px;
                border-radius: 8px;
                background-color: $PRIMARY-SURFACE;
                justify-content: center;
                align-items: center;
            }
    
            .cardContent{
                display: flex;
                flex-direction: column;
    
                >:first-child{
                    font-size: 20px;
                    font-weight: 500;
                    font-family: Roboto;
                    color: $NEUTRAL-90;
                }
                >:last-child{
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Roboto;
                    color: $NEUTRAL-60;
                }
    
            }
        }
        .expandIcon{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .expandContent{
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 100%;
        gap: 10px;
        border-radius: 8px;

        .expandTitle{
            width: 100%;
            >span{
                font-family: Roboto;
                font-size: 20px;
                font-weight: 500;
                color: $NEUTRAL-100;
            }
        }

        .divider{
            width: 100%;
            height: 1px;
            background-color: $NEUTRAL-30;
        }

        .expandForm{
            display: flex;
            flex-direction: column;
            gap: 8px;

            .expandChildForm{
                display: flex;
                width: 100%;

                .title{
                    width: 30%;
                    align-items: center;    
                    
                    >span{
                        font-family: Roboto;
                        font-weight: 500;
                        font-size: 14px;
                        color: $NEUTRAL-90;
                    }
                }
                .colon{
                    width: 3%;
                    align-items: center;
                    text-align: center;
                    
                    >span{
                        font-family: Roboto;
                        font-weight: 500;
                        font-size: 14px;
                        color: $NEUTRAL-90;
                    }
                }
                .value{
                    width: 65%;
                    align-items: center;
                    
                    >span{
                        font-family: Roboto;
                        font-weight: 500;
                        font-size: 14px;
                        color: $NEUTRAL-90;
                    }
                }
            }
        }
    }
}