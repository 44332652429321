@import "../../../../theme/variables.scss";
@import "../../../../theme/global.scss";
.modalAddWorkLocation {
  transition: 0.4s ease-out;
  width: 661px;
  height: 768px;
  height: 99%;
  overflow-y: auto;
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  @include flexCol(1rem);
  position: relative;
  .headerModal {
    padding: 1rem;
    @include flexRow(1rem);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $NEUTRAL-30;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 999;
    > strong {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    > .closeCircle {
      cursor: pointer;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }

  .footerModal {
    background-color: $NEUTRAL-10;
    @include flexRow(0);
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-inline: 1rem;
    position: sticky;
    bottom: 0;
    padding: 1rem;
    border-top: 1px solid $NEUTRAL-30;
    flex-grow: 1;
    z-index: 9999;
    // border-bottom: 1px solid $NEUTRAL-30;
  }

  .uploadLocation {
    @include flexCol(4px);
    justify-content: flex-start;
    padding-inline: 1rem;
    > strong {
      color: $NEUTRAL-100;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      span {
        color: $DANGER-MAIN;
      }
    }
    .chooseFile {
      background-color: $PRIMARY-BACKGROUND;
      border: 1px dashed $PRIMARY-MAIN;
      border-radius: 8px;
      @include flexCol(12px);
      justify-content: center;
      align-items: center;
      padding-block: 28px;
      font-family: Roboto;
      height: 196px;
      > img {
        width: 100%;
        height: 195px;
        object-fit: contain;
      }
      > .viewerCol {
        @include flexCol(0);
        align-items: center;
        > span {
          color: $PRIMARY-MAIN;
          font-size: 16px;
          font-weight: 600;
        }
        > p {
          color: #746e82;
          font-size: 14px;
        }
      }
    }
  }
  .inputField {
    padding-inline: 1rem;
    @include flexCol(1rem);
    .viewColInput {
      @include flexRow(1rem);
    }
    .containerMap {
      height: 300px;
      width: 100%;
    }
    .coordinatInput {
      @include flexRow(8px);
      align-items: flex-end;
      .btnCoord {
        position: relative;
        top: -4px;
      }
    }
  }
}
.modalAddWorkLocation[is_filled="true"] {
  position: absolute;
  right: -50%;
}
.modalAddWorkLocation[is_transition="true"] {
  right: 0% !important;
}
