@import "../../../theme/variables.scss";
@import "../../../theme/global.scss";

@mixin headerCardText() {
  color: $NEUTRAL-100;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
@mixin textDescription() {
  color: $NEUTRAL-60;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.containerIsEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  // background-image: url("../../../assets/Images/bg-permata-security.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: top;
  .wrapperEmpty {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    width: 600px;
    justify-content: center;
    align-items: center;
    .headerText {
      h2 {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
      p {
        color: $NEUTRAL-60;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
      > img {
        width: 276.95px;
        height: 207.38px;
        flex-shrink: 0;
      }
    }
  }
  .modalAddWorkLocation {
    width: 661px;
    height: 768px;
    overflow-y: scroll;
    background-color: $NEUTRAL-10;
    border-radius: 8px;
    @include flexCol(1rem);
    .headerModal {
      padding: 1rem;
      @include flexRow(1rem);
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $NEUTRAL-30;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL-10;
      z-index: 999;
      > strong {
        color: $NEUTRAL-100;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      > .closeCircle {
        cursor: pointer;
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }
    }
    .footerModal {
      background-color: $NEUTRAL-10;
      @include flexRow(0);
      width: 100%;
      justify-content: flex-end;
      padding-inline: 1rem;
      position: sticky;
      bottom: 0;
      padding: 1rem;
      border-top: 1px solid $NEUTRAL-30;
    }

    .uploadLocation {
      @include flexCol(4px);
      justify-content: flex-start;
      padding-inline: 1rem;
      > strong {
        color: $NEUTRAL-100;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        span {
          color: $DANGER-MAIN;
        }
      }
      .chooseFile {
        background-color: $PRIMARY-BACKGROUND;
        border: 1px dashed $PRIMARY-MAIN;
        border-radius: 8px;
        @include flexCol(12px);
        justify-content: center;
        align-items: center;
        padding-block: 28px;
        font-family: Roboto;
        height: 196px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        > .viewerCol {
          @include flexCol(0);
          align-items: center;
          > span {
            color: $PRIMARY-MAIN;
            font-size: 16px;
            font-weight: 600;
          }
          > p {
            color: #746e82;
            font-size: 14px;
          }
        }
      }
    }
    .inputField {
      padding-inline: 1rem;
      @include flexCol(1rem);
      .viewColInput {
        @include flexRow(1rem);
      }
      .containerMap {
        height: 200px;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .containerIsEmpty {
    .wrapperEmpty {
      .headerText {
        h2 {
          font-size: 20px !important;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .uploadModal {
    padding: 0 !important;
  }
}
