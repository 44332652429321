@import "../../../theme/variables.scss";
.wrapperTag {
  .tag {
    padding: 4px 16px;
    display: flex;
    border-radius: 17px;
    align-items: center;
    background-color: $NEUTRAL-100;
    width: max-content;
    text-align: center;

    .text {
      font-size: 12px;
      font-weight: 500;
      // color: $NEUTRAL-10 !important;
    }
  }

  .tag[color="red"] {
    background-color: $DANGER-SURFACE;
    color: $DANGER-MAIN;
  }
  .tag[color="green"] {
    background-color: #CEEAD3;
    color: $SUCCESS-MAIN;
  }
  .tag[color="yellow"] {
    background-color: #F9ECD3;
    color: #E78B00;
  }
  .tag[color="blue"] {
    background-color: $PRIMARY-SURFACE;
    color: $PRIMARY-MAIN;
  }
}
