@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
}

.leftSide {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
}

.rightSide {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0;
  .totalAllData {
    margin-right: 36px;
    gap: 2px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $NEUTRAL-100;
    > strong {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.content {
  padding-right: 16px;
  font-size: 12;
  font-weight: 400;
  color: $NEUTRAL-100;
}

.contentBold {
  font-size: 12;
  font-weight: 600;
  color: $NEUTRAL-100;
}

.pageIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  // background-color: $NEUTRAL_30;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.page {
  padding: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: $NEUTRAL-60;
}

.selectedPage {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  // background-color: $PRIMARY_MAIN;
  color: $NEUTRAL-100;
}

.disabled {
  color: $NEUTRAL-70 !important;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
    gap: 0;

    .leftSide {
      width: 100%;
      justify-content: center;
      span {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        min-width: 56px;
      }
      span:nth-child(1) {
        min-width: auto;
      }
    }

    .rightSide {
      width: 100%;
      // justify-content: center;
      padding-right: 1rem !important;
      margin: 0;
    }
  }
}
