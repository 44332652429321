@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 515px;
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {

    .top {
      display: flex;
      align-items: center;
      gap: 8px;
      >h3 {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }

    >span {
      font-size: 12px;
      color: $NEUTRAL-70;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
  }
}