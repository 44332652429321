@use "theme/color";
@use "theme/mixin";

.viewerSkeletonCards {
  padding: 20px;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin-inline: auto;
  @include mixin.flexCol(1rem);
  .viewRow {
    margin-top: 1rem;
    @include mixin.flexRow(1rem);
  }
  > .between {
    @include mixin.flexBetween();
    .viewRow {
      @include mixin.flexRow(1rem);
      margin-top: 0;
    }
  }
  > .wrapCards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1rem;
    &:last-child {
      padding-bottom: 1rem;
    }
  }
}
