@import "../../../theme/variables.scss";

.editorWrapper {
  max-height: 100%;
  height: 100%;
  background-color: $NEUTRAL_10;
  z-index: 0;
  width: 100%;

  .toolbar {
    z-index: 2;
    margin-bottom: 0px;

    &.sticky {
      position: sticky;
      top: 0;
    }
  }

  .toolbarIcon {
    border: none;
  }

  .toolbarIcon[aria-selected="true"] {
    box-shadow: none;
    background: $NEUTRAL-30;
  }

  .editor {
    padding: 16px;
    background-color: $NEUTRAL_10;
    width: 100%;
    border-radius: 0 0 12px 12px;
    border-top: 0;
  }
}
