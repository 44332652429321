@use "theme/mixin";
@use "theme/color";

.HistoryActivity {
  @include mixin.flexCol(0);
  background-color: color.$NEUTRAL-10;
  overflow-y: auto;
  border-top: 1px solid color.$NEUTRAL-30;
  &[is-zoom-out="true"] {
    border-right: 1px solid color.$NEUTRAL-30;
    border-left: 1px solid color.$NEUTRAL-30;
    border-radius: 8px;
  }
  .viewerDetail {
    width: 100%;
    padding: 18px 24px;
    @include mixin.flexBetween();
    align-items: center;
    .viewLeft {
      width: 100%;
      @include mixin.flexRow(10px);
      align-items: center;
      .imgProfile {
        padding: 10px;
        border-radius: 50%;
        background-color: color.$NEUTRAL-30;
        @include mixin.flexRow(0);
        align-items: center;
        > img {
          width: 20px;
          height: 20px;
        }
      }
      .names {
        @include mixin.flexCol(0);

        > span {
          color: #000000;
          font-size: 16px;
        }
        > .activities {
          @include mixin.flexRow(0);
          .desc {
            @include mixin.flexRow(8px);
            color: color.$NEUTRAL-60;
            align-items: center;
            font-size: 12px;

            > p {
              font-size: 12px;
            }
          }
        }
      }
    }
    .viewRight {
      @include mixin.flexRow(10px);
      .condition {
        @include mixin.flexRow(0);
        justify-content: center;
        align-items: center;
        width: 84px;
        padding-block: 2px;
        border-radius: 20px;
        > span {
          font-size: 12px;
          font-weight: 500;
        }
        &[condition="siaga"] {
          background-color: #f9ecd3;
          color: #e78b00;
        }
        &[condition="aman"] {
          background-color: #ceead3;
          color: color.$SUCCESS-MAIN;
        }
        &[condition="waspada"] {
          background-color: #f5ddda;
          color: color.$DANGER-MAIN;
        }
      }
      .iconMax {
        cursor: pointer;
        height: 20px;
        width: 20px;
        color: color.$PRIMARY-MAIN;
      }
    }
  }
}
