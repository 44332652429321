@import "../../../../theme/variables.scss";
@import "../../../../theme/global.scss";

.modalAddWorkLocation {
  transition: 0.4s ease-out;
  width: 661px;
  height: 768px;
  height: 100%;
  overflow-y: auto;
  background-color: $NEUTRAL-10;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @include flexCol(1rem);
  position: relative;
  .headerModal {
    padding: 1rem;
    @include flexRow(1rem);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $NEUTRAL-30;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 999;
    > strong {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    > .closeCircle {
      cursor: pointer;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }

  .viewBetweenCol {
    height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 1rem;
    overflow-y: hidden;

    .tableData {
      @include flexCol(1rem);
      position: relative;
      overflow-y: hidden;

      .inputFieldSticky {
        position: sticky;
        top: 0;
        z-index: 11;
      }
      .customStickyToTableHead {
        position: sticky;
        // top: calc(57px + 1rem + 3.8rem);
        top: 0;
      }
      .customStickyToTableHead.addTopSticky {
        top: 0;
      }
      &.hide {
        &::after {
          content: "";
          position: fixed;
          top: 0;
          height: 134px;
          width: 100%;
          background-color: #fff;
          z-index: 10;
        }
      }
      .stickySearchBar {
        position: sticky;
        top: 0;
        z-index: 11;
      }
      .stickySearchBar.addTopSticky {
        top: 0;
      }
      .addBorder {
        height: 100%;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        padding: 12px;
        position: sticky !important;
        top: 0;
        z-index: 11;
      }
      .addRowGap {
        @include flexCol(1rem);
      }
      .wrapTable {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        height: 100%;
        // flex-grow: 1;
        overflow-y: hidden;
        .personilFilled {
          @include flexCol(8px);
          > label {
            color: $NEUTRAL-100;
            font-size: 14px;
            font-weight: 500;
          }
          .namesSelected {
            @include flexRow(12px);
            align-items: center;
            border: 1px solid $NEUTRAL-30;
            padding: 8px 16px;
            border-radius: 8px;
            > img {
              border-radius: 50%;
              width: 24px;
              height: 24px;
              object-fit: contain;
            }
            > div {
              @include flexCol(0);
              > h5 {
                color: $NEUTRAL-100;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
              }
              > span {
                color: $NEUTRAL-80;
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
        }
        .labelSquad {
          color: $NEUTRAL-100;
          font-size: 14px;
          font-weight: 500;
          position: sticky;
          top: 0;
          > span {
            color: $DANGER-MAIN;
          }
        }
        .withBorder {
          border: 1px solid $NEUTRAL-30;
          // padding: 12px;
          border-radius: 8px;
          flex-grow: 1;
          height: 100%;
          width: 100%;
          // position: ;
          top: calc(182px);
          overflow: hidden;
          .overfolowContain {
            height: 100%;
            width: 100%;
            // flex-grow: 1;
            // overflow: auto;
            .tabs {
              display: flex;
              // justify-content: space-around;
              padding-block: 13px;
              // border-bottom: 1px solid $NEUTRAL-30;

              span {
                border-bottom: 1px solid $NEUTRAL-30;
                border-bottom: 1px solid $NEUTRAL-30;

                color: $NEUTRAL-50;
                font-size: 14px;
                cursor: pointer;
                width: 100%;
                text-align: center;
                &:hover {
                  color: $PRIMARY-HOVER;
                }
                &.active {
                  border-bottom: 2px solid $PRIMARY-MAIN;
                  color: $PRIMARY-MAIN;
                  padding-bottom: 13px;
                }
              }
            }
            .sticky {
              position: sticky;
              top: 0;
            }
            .wrapMainContent {
              padding-inline: 12px;
              .sticky {
                position: sticky !important;
                top: 0 !important;
                background-color: $NEUTRAL-10;
                height: 50px;
              }
              .scrollContain {
                overflow-y: auto;
                height: 50vh;
              }
            }
          }
        }
      }
    }
    .tableData.addHeightTop {
      &::after {
        height: 160px;
      }
    }
  }

  .footerModal {
    background-color: $NEUTRAL-10;
    @include flexRow(0);
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-inline: 1rem;
    position: sticky;
    bottom: 0;
    padding: 1rem;
    border-top: 1px solid $NEUTRAL-30;
  }
}
.modalAddWorkLocation[is_filled="true"] {
  position: absolute;
  right: -50%;
}
.modalAddWorkLocation[is_transition="true"] {
  right: 0% !important;
}
