@use "theme/color";
@use "theme/variables";
@use "theme/mixin";

.containerSchemaAbsenceSetting {
  // padding: 20px;
  // padding-inline: 0;
  width: 100%;
  // height: 100%;
  height: calc(100vh - 56px);
  overflow-y: hidden;
  max-width: 1440px;
  @include mixin.flexCol(1rem);
  .header {
    @include mixin.flexBetween();
    align-items: center;
    padding: 20px;
    padding-bottom: 0;

    > h1 {
      color: #000;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  .wrapperCards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
    gap: 20px;
    padding: 20px;
    padding-top: 0;

    .card {
      @include mixin.flexCol(1rem);
      justify-content: space-between;
      height: 190px;
      border-radius: 12px;
      background: color.$NEUTRAL-10;
      box-shadow: 0 2px 10px #80808042;
      transition: 0.3s ease-out;
      padding: 1rem;
      position: relative;
      overflow: hidden;
      > img {
        width: 230px;
        height: 230px;
        position: absolute;
        right: -6rem;
        opacity: 0.15;
        rotate: -18deg;
        bottom: -75px;
        object-fit: contain;
      }

      &:hover {
        box-shadow: none;
      }
      .headerCard {
        span {
          color: color.$NEUTRAL-100;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
      .description {
        @include mixin.flexCol(8px);
        > div {
          @include mixin.flexRow(4px);
          align-items: center;
          color: color.$NEUTRAL-80;
          .iconDesc {
            flex-shrink: 0;
          }
          span {
            font-size: 12px;
            font-family: Roboto;
          }
        }
      }
      .footerCard {
        @include mixin.flexRow(4px);
        align-items: center;
        color: color.$PRIMARY-MAIN;
        cursor: pointer;
        h3 {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .iconArrowRight {
          flex-shrink: 0;
          transform: rotate(180deg);
        }
      }
    }
    .addBtnSchema {
      background: color.$NEUTRAL-10;
      height: 190px;
      @include mixin.flexCol(0);
      border: 1px dashed color.$NEUTRAL-50;
      border-radius: 12px;
      cursor: pointer;
      color: color.$PRIMARY-MAIN;
      box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.079),
        0px 10px 32px -4px rgba(24, 39, 75, 0.066);
      transition: 0.3s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: none;
      }
      .addBtnIcon {
        flex-shrink: 0;
      }
      > span {
        font-size: 14px;
      }
    }
  }
}
