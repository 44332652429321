@use "theme/mixin";
@use "theme/color";

.containerListLocation {
  // padding: 1rem;
  @include mixin.flexCol(1rem);
  width: 100%;
  max-width: 1440px;
  height: calc(100vh - 56px);
  overflow: hidden;
  position: relative;
  .between {
    @include mixin.flexBetween();
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
    > strong {
      color: color.$NEUTRAL-100;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .cardLocation {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
    gap: 1rem;
    padding: 20px;
    padding-top: 0;
    .card {
      overflow: hidden;
      display: flex;
      //   width: 281px;
      height: 287px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      background-color: color.$NEUTRAL-10;
      box-shadow: 0 6px 10px rgba(47, 47, 47, 0.109);
      border-radius: 12px;
      transition: 0.3s ease;
      &:hover {
        box-shadow: none;
        box-shadow: 0 0 5px color.$NEUTRAL-30;
      }
      .image {
        width: 100%;
        height: 190px;
        background-color: color.$NEUTRAL-40;
        display: flex;
        > img[alt="image-location"] {
          width: 100%;
          height: 190px;
          // object-fit: contain;
          object-fit: cover;
        }
      }
      .description {
        width: 100%;
        padding: 1rem;
        @include mixin.flexCol(12px);
        align-items: flex-start;
        .personil {
          @include mixin.flexCol(4px);
          > strong {
            color: color.$NEUTRAL-100;
            font-size: 14px;
            font-weight: 500;
          }
          div {
            @include mixin.flexRow(2px);
            color: color.$NEUTRAL-70;
            .peopleIcon {
              height: 16px;
              width: 16px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        .btnCard {
          @include mixin.flexRow(4px);
          align-items: center;
          color: color.$PRIMARY-MAIN;
          cursor: pointer;
          span {
            font-size: 14px;
            font-weight: 500;
          }
          .arrowIcon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            rotate: 180deg;
          }
        }
      }
    }
    .card.addBtnCard {
      cursor: pointer;
    }
  }
}

.lottieWrap {
  @include mixin.flexColumnAlignCenter();
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  width: max-content;
  .lottie {
    width: 160px;
    height: 100px;
    border-radius: 8px;
  }
}
