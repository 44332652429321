@import "../../../theme/variables.scss";

.container{
    display: flex;
    width: 620px;
    height: fit-content;
    max-height: 600px;
    flex-direction: column;
    gap: 12px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background-color: #fff;
    font-family: Roboto;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $NEUTRAL-30;
        padding-bottom: 12px;

        >span{
            font-weight: 600;
            font-size: 16px;
            color: $NEUTRAL-100;
        }

        .iconClose{
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .divider{
        width: 100%;
        height: 1px;
        background-color: $NEUTRAL-30;
    }

    .body{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-right: 8px;
        overflow-y: auto;

        .cardBox{
            border: 1px solid $NEUTRAL-30;
            border-radius: 8px;

            .contentWrapper{
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 12px 16px 12px 16px;
                width: 100%;

                .childContent{
                    display: flex;
                    justify-content: space-between;

                    >:first-child {
                        font-weight: 400;   
                        font-size: 14px;
                        color: $NEUTRAL-90;
                    }
                    >:last-child {
                        font-weight: 400;   
                        font-size: 14px;
                        color: $NEUTRAL-100;
                    }
                }
                .divider{
                    width: 100%;
                    height: 1px;
                    background-color: $NEUTRAL-30;
                }
                .totalWrapper{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    font-weight: 500;
                    font-size: 14px;
                    color: $NEUTRAL-100;
                }
            }

        }
        
        .historyBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;

            .title{
                text-align: start;
                font-weight: 500;
                font-size: 14px;
                color: $NEUTRAL_100;
            }

            .historyContentWrapper{
                display: flex;
                flex-direction: column;
                width: 100%;

                .historyChildContent{
                    display: flex;

                    .dateWrapper{
                        width: 30%;
                        display: flex;
                        gap: 8px;
    
                        .linkedNumber{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
    
                            >span{
                                width: 20px;
                                height: 20px;
                                align-items: center;
                                align-content: center;
                                text-align: center;
                                padding: 2px 4px 2px 4px;
                                border-radius: 4px;
                                font-size: 12px;
                                color: $NEUTRAL-10;
                                background-color: $PRIMARY-MAIN;
                            }
    
                            &.notPaid{
                                >span{
                                    background-color: $NEUTRAL-40;
                                    color: $NEUTRAL-100;
                                }
                            }
    
                            .verticalDivier{
                                height: 50%;
                                width: 1px;
                                border: 1px dashed $PRIMARY-MAIN;
    
                                &.notPaid{
                                    border: 1px dashed $NEUTRAL-40;
                                }
                            }
                        }

                        >span{
                            font-weight: 400;
                            font-size: 14px;
                            color: $NEUTRAL-100;
                        }
                    }
                    .installmentText{
                        width: 40%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        font-weight: 400;
                        font-size: 14px;
                        color: $NEUTRAL_100;
                        padding: 0px 0px 24px 0px;
                    }
                    .paidWrapper{
                        width: 30%;
                        height: 100%;
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        justify-content: flex-end;
                        
                        >span{
                            font-weight: 500;
                            font-size: 14px;
                            color: $SUCCESS_MAIN;
                        }

                        &.notPaid{
                            width: 30%;
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .footer{
        display: flex;
        flex-direction: row-reverse;
        padding: 12px 0px 0px 0px;
        border-top: 1px solid $NEUTRAL-30;
    }
}