@use "../../../../theme/color" as *;
@use "../../../../theme/mixin" as *;

.wrapperSkeletonDetailClient {
  @include flexCol(1rem);
  padding: 1rem;
  width: 100%;
  .card {
    @include flexCol(12px);
    height: 292px;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    padding: 1rem;
    > div {
      @include flexBetween();
      gap: 1rem;
    }
  }
  .viewRow {
    width: 100%;
    @include flexRow(1rem);
    align-items: center;
  }
}
