.Body {
  width: 100%;
  height: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Header {
  display: flex;
  padding: 22px 30px 22px 48px;
  padding-block: 1rem;
  align-items: center;
  gap: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e7;
  background: #fff;
  padding-inline: 221px;
  @media (max-width: 768px) {
    padding-inline: 1rem;
  }
  img {
    width: 39.423px;
    height: 30.132px;
    flex-shrink: 0;
  }
  span {
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
}

.Content {
  position: relative;
  width: 100%;
  .backButton {
    position: fixed;
    left: calc(160px - 32px);
    top: calc(63px + 30px);
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    @media (max-width: 1024px) {
      top: 1.5rem;
      left: 2rem;
      position: absolute;
    }

    @media (max-width: 768px) {
      top: 1rem;
      left: 1rem;
      position: absolute;
    }

    .backIcon {
      flex-shrink: 0;
    }
    > span {
      color: #2e3192;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .backgroundLinear {
    width: 100%;
    height: 100%;
    background: url("../../../assets/Images/bg-permata-security.png");
    background-repeat: round;
    z-index: -1;
    background-size: contain;
    background-position: top;
    position: fixed;
    top: -1rem;
    @media (max-width: 1024px) {
      background-repeat: no-repeat;
      top: 1.5rem;
    }
    @media (max-width: 768px) {
      background-repeat: no-repeat;
      top: 3rem;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 24px;
  @media (max-width: 768px) {
    padding-block: 1rem;
  }
  padding-inline: 221px;
  gap: 24px;

  .Heading {
    width: 100%;
    span {
      font-size: 32px;
      font-weight: 600;
      color: #0a0a0a;
      font-family: "Poppins", sans-serif;
    }
  }

  .Paragraph {
    width: 100%;

    @media (max-width: 1024px) {
      margin-top: 2rem;
    }

    @media (max-width: 768px) {
      margin-top: 1.5rem;
    }
    span {
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      line-height: 24px;
    }
  }
  .Paragraph[is_single_page="true"] {
    margin-top: unset !important;
  }

  .Chapter {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #0a0a0a;
      font-family: "Poppins", sans-serif;
      line-height: 24px;
    }

    .WrapperChapter {
      display: flex;
      flex-direction: column;

      label {
        color: #0a0a0a;
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }

      .SubDesc {
        padding-top: 24px;
        span {
          color: #0a0a0a;
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins", sans-serif;
        }
      }

      .HeadDesc {
        padding-bottom: 24px;
        span {
          color: #0a0a0a;
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins", sans-serif;
          b {
            font-weight: 700;
            font-family: "Poppins", sans-serif;
          }
        }
      }
    }

    ul {
      padding-inline: 15px;
      li {
        color: #0a0a0a;
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  .MoreInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .Footer {
    width: 100%;
    span {
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
    }
  }
}

@media (max-width: 1024px) {
  .Content {
    padding-inline: 2rem;
    .MoreInfo {
      gap: 5px;
    }
  }
}

@media (max-width: 768px) {
  .Content {
    padding-inline: 1rem;
  }
}

@media (max-width: 400px) {
  .Content {
    .Heading {
      span {
        font-size: 24px;
      }
    }
  }
}
