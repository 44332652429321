@use "../../../theme/variables.scss" as *;

.container {
  display: flex;
  align-items: flex-start;
  gap: 7px;

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: clip;
    position: relative;

  

    .time {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      padding-top: 4px;

      >span {
        font-size: 12px;
        color: $NEUTRAL-90;
      }
    }

  }





}
