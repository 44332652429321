@use "theme/color";
@use "theme/variables";
@use "theme/mixin";

.wrapperDetailWorkSchedule {
  width: 100%;
  &.isEmpty {
    height: calc(100vh - 56px) !important;
  }
  height: 100%;
  padding: 20px;
  max-width: 1440px;
  @include mixin.flexCol(20px);
  .header {
    @include mixin.flexRow(12px);
    align-items: center;
    justify-content: space-between;
    > div {
      @include mixin.flexRow(12px);
      align-items: center;
      .back {
        @include mixin.flexRow(12px);
        align-items: center;
        cursor: pointer;
        h3 {
          color: color.$NEUTRAL-100;
          font-size: 24px;
        }
      }
      .wrapDateTime {
        @include mixin.flexRow(12px);
        align-items: center;
        .date {
          @include mixin.flexRow(8px);
          align-items: center;
          cursor: pointer;
          h3 {
            color: color.$NEUTRAL-70;
            font-size: 24px;
            font-weight: 500;
          }
          .calendarIcon {
            transition: 0.2s;
            cursor: pointer;
            &:hover {
              color: color.$PRIMARY-HOVER;
            }
          }
        }
      }
      .status {
        background-color: color.$PRIMARY-SURFACE;
        border-radius: 17px;
        padding: 2px 8px;
        @include mixin.flexRow(8px);
        align-items: center;

        span {
          color: #2e3192;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .uploadSchedule {
      @include mixin.flexRow(10px);
      align-items: center;
      border: 1px solid color.$NEUTRAL-40;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      overflow: auto;

      img {
        cursor: pointer;
        height: 16px;
        width: 16px;
        flex-shrink: 0;
        color: color.$NEUTRAL-90;
      }
      span {
        color: color.$NEUTRAL-100;
        font-size: 14px;
      }
    }
  }
  .mainContainDetailWorkSchedule {
    height: 100%;
    &.viewPortStyles {
      height: calc(100vh - 56px - 38.5px - 60px) !important;
    }
    background-color: color.$NEUTRAL-10;
    box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
      0px 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-radius: 8px;
    width: 100%;
    @include mixin.flexRow(0);
    .listPersonil {
      width: 258px;
      height: 100%;
      border-right: 1px solid color.$NEUTRAL-30;
      flex-shrink: 0;
      .textListPersonil {
        padding: 12px;
        @include mixin.flexCol(12px);
        justify-content: center;
        padding-bottom: 16px;
        > div {
          @include mixin.flexBetween();
          position: relative;
          h3 {
            color: color.$NEUTRAL-100;
            font-size: 16px;
            font-weight: 600;
          }
          .icon {
            cursor: pointer;
            color: color.$NEUTRAL-70;
            flex-shrink: 0;
            transition: 0.2s;
            &[is-focus="true"] {
              color: color.$PRIMARY-MAIN;
            }
          }
          .viewDropdownFiltered {
            padding: 8px;
            padding-right: 14px;
            z-index: 999;
            right: 0;
            top: 2rem;
            position: absolute;
            width: auto;
            background-color: color.$NEUTRAL-10;
            border-radius: 8px;
            box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
              0px 12px 42px -4px rgba(24, 39, 75, 0.12);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.148);
            @include mixin.flexCol(8px);
            > div {
              @include mixin.flexRow(6px);
              align-items: center;
            }
          }
        }
      }
      .personil {
        .between {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid color.$NEUTRAL-30;
          align-items: center;
          position: relative;
          > div {
            @include mixin.flexRow(6px);
            padding: 8px;
            align-items: center;
            > img {
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }
            .names {
              @include mixin.flexCol(0);
              font-family: Roboto;
              span {
                color: color.$NEUTRAL-100;
                font-size: 12px;
                font-weight: 500;
              }
              p {
                color: color.$NEUTRAL-70;
                font-size: 10px;
              }
            }
          }
          .nonShift {
            background-color: color.$PRIMARY-SURFACE;
            border-radius: 20px;
            padding: 2px 8px;
            @include mixin.flexRow(0);
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            > span {
              font-weight: 500;
              font-size: 12px;
              color: #2e3192;
            }
          }
          .floatRight {
            position: absolute;
            right: 1rem;
            flex-shrink: 0;
            cursor: pointer;
          }
        }
      }
    }
    .schedules {
      width: 100%;
      height: 100%;
      @include mixin.flexCol(8px);
      overflow-x: auto;
      overflow-y: hidden;
      .month {
        @include mixin.flexRow(0);
        justify-content: center;
        span {
          padding: 12px 12px 8px 12px;
          color: color.$PRIMARY-MAIN;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .wrapperDateTwoWeeks {
        width: 100%;
        @include mixin.flexRow(0);
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        .week {
          width: max-content;
          // width: calc(665px / 2);
          // 665px
          // width: 335px;
          height: 100%;
          flex-shrink: 0;
          // &:first-child {
          //   border-right: 1px solid color.$NEUTRAL-30;
          // }
          @include mixin.flexCol(3px);
          > div:first-child {
            @include mixin.flexRow(0);
            justify-content: space-around;
            padding: 5.8px;
            width: 100%;
            .days {
              @include mixin.flexCol(0);
              font-family: Roboto;
              align-items: center;
              padding-inline: 12px;
              span {
                padding: 3.9px;
                color: color.$NEUTRAL-70;
                font-size: 10px;
                font-weight: 500;
              }
              span:nth-child(2) {
                color: color.$NEUTRAL-100;
                font-weight: 600;
                font-size: 11px;
              }
            }
          }
          .selectedDay {
            display: flex;
            flex-direction: column;
            width: 100%;
            .bullets {
              width: 100%;
              border-bottom: 1px solid color.$NEUTRAL-30;
              display: flex;
              height: 43px;
              padding-inline: 8px;
              // justify-content: space-around;
              align-items: center;
              > div {
                display: flex;
                justify-content: center;
                position: relative;
                width: 100%;
                // span {
                // width: 10px;
                // width: 24px;
                // height: 24px;
                // border: 1px solid color.$NEUTRAL-40;
                // border-radius: 50%;
                // cursor: pointer;
                // transition: 0.4s;

                // &:hover {
                //   border-color: color.$PRIMARY-HOVER;
                // }
                // &[is_open="true"] {
                //   background-color: color.$NEUTRAL-30;
                //   border-color: color.$PRIMARY-HOVER;
                // }
                // &[is_already_shift="true"] {
                //   border: none;
                //   display: flex;
                //   align-items: center;
                //   padding-top: 8px;
                //   transition: none;
                //   > span {
                //     text-align: center;
                //     font-size: 14px;
                //     font-weight: 700;
                //     border: none;
                //   }
                // }
                // }
                .chooseShift {
                  position: absolute;
                  height: auto;
                  background-color: color.$NEUTRAL-10;
                  z-index: 99;
                  @include mixin.flexCol(4px);
                  top: 2rem;
                  left: 1rem;
                  width: 100%;
                  padding: 8px;
                  border-radius: 6px;
                  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.174);
                  width: 70px;
                  > ul li {
                    list-style: none;
                    font-size: 12px;
                    color: color.$NEUTRAL-100;
                    width: 56px;
                    font-weight: 500;
                    padding: 4px;
                    cursor: pointer;
                    border-radius: 6px;
                    transition: 0.4s;
                    &:hover {
                      background-color: color.$PRIMARY-SURFACE;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
