@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.loadingPost {
  background-color: color.$NEUTRAL-10;
  padding: 10px 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.wrapperListPatrolPoint {
  width: 100%;
  height: calc(100vh - 56px);
  max-width: 1440px;
  background-color: color.$PRIMARY-BACKGROUND;
  overflow: hidden;

  .mainContainListPatrolPoint {
    height: 100%;
    @include mixin.flexCol(1rem);
    justify-content: flex-start;
    overflow: hidden;
    height: calc(100vh - 56px);
    position: relative;
    .between {
      padding: 20px;
      padding-bottom: 0;
      @include mixin.flexBetween();
      .back {
        @include mixin.flexRow(12px);
        justify-content: flex-start;
        align-items: center;
        > .backIcon {
          color: color.$NEUTRAL-100;
          cursor: pointer;
        }
        > span {
          color: color.$NEUTRAL-100;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
        }
      }
    }

    .viewFilter {
      @include mixin.flexBetween();
      align-items: center;
      padding-inline: 20px;
      padding-bottom: 0;
      .wrapSortPatrol {
        background-color: color.$NEUTRAL-10;
        width: 420px;
        height: 99%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        position: absolute;
        right: -50%;
        overflow: hidden;

        .header {
          @include mixin.flexBetween();
          padding: 1rem;
          // padding-bottom: 0;
          border-bottom: 1px solid color.$NEUTRAL-30;
          span {
            color: color.$NEUTRAL-100;
            font-size: 16px;
            font-weight: 500;
          }
          .iconClose {
            cursor: pointer;
          }
        }
        .wrapBetween {
          height: 100%;
          > .mainContain {
            padding: 1rem;
            @include mixin.flexCol(0);
            height: 100%;
            overflow-y: auto;
            padding-bottom: 9rem;
            .listCard {
              @include mixin.flexRow(1rem);
              align-items: center;
              cursor: grab;
              &:active {
                cursor: grabbing;
              }
              .burgerMenuIcon {
                flex-shrink: 0;
              }
              .wrapDivider {
                width: 100%;
                @include mixin.flexRow(12px);
                border-bottom: 1px solid color.$NEUTRAL-30;
                padding-block: 12px;
                align-items: center;
                .wrapImage {
                  height: 56px;
                  width: 56px;
                  overflow: hidden;
                  border-radius: 12px;
                  display: flex;
                  align-items: center;
                  min-width: 56px;
                  > img[alt="image-location-patrol"] {
                    border-radius: 12px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    flex-shrink: 0;
                  }
                }
                .nameLocation {
                  @include mixin.flexCol(2px);
                  font-family: variables.$BASE-FONT-FAMILY;
                  strong {
                    overflow: hidden;
                    color: color.$NEUTRAL-100;
                    font-size: 14px;
                    font-weight: 500;
                  }
                  span {
                    overflow: hidden;
                    color: color.$NEUTRAL-70;
                    font-size: 14px;
                    font-weight: 400;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                  }
                  // &:is(strong, span) {
                  //   background-color: salmon;
                  // }
                }
              }
              .wrapDivider.unsetDivider {
                border: unset;
              }
            }
          }
          .btnSave {
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 1rem;
            border-top: 1px solid color.$NEUTRAL-30;
            display: flex;
            justify-content: flex-end;
            background-color: color.$NEUTRAL-10;
          }
        }
        transition: 0.3s;
      }

      .wrapSortPatrol[is_transition="true"] {
        right: 0% !important;
      }
    }
    .cardLocation {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
      gap: 1rem;
      padding: 20px;
      padding-top: 4px;
      .card {
        overflow: hidden;
        display: flex;
        //   width: 216px;
        height: 100%;
        min-height: 328px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: color.$NEUTRAL-10;
        box-shadow: 0 2px 10px #53535326;
        border-radius: 12px;
        transition: 0.3s ease;
        &:hover {
          box-shadow: none;
        }
        .img {
          display: flex;
          // background-color: color.$NEUTRAL-40;
          width: 100%;
          height: 196px;
          > img[alt="image-location"] {
            width: 100%;
            height: 196px;
            object-fit: contain;
          }
        }
        .description {
          width: 100%;
          padding: 1rem;
          @include mixin.flexCol(12px);
          align-items: flex-start;
          height: 40%;
          .personil {
            @include mixin.flexCol(4px);
            > strong {
              color: color.$NEUTRAL-100;
              font-size: 14px;
              font-weight: 500;
            }
            div {
              @include mixin.flexRow(2px);
              color: color.$NEUTRAL-70;
              .peopleIcon {
                height: 16px;
                width: 16px;
              }
              span {
                overflow: hidden;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                overflow: hidden;
                color: color.$NEUTRAL-70;
                font-family: Roboto;
                font-size: 12px;
              }
            }
          }
          .btnCard {
            @include mixin.flexRow(4px);
            align-items: center;
            color: color.$PRIMARY-MAIN;
            cursor: pointer;
            span {
              font-size: 14px;
              font-weight: 500;
            }
            .arrowIcon {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              rotate: 180deg;
            }
          }
        }
        .detailQr {
          @include mixin.flexBetween();
          width: 100%;
          padding: 12px 1rem;
          background-color: color.$PRIMARY-SURFACE;
          cursor: pointer;
          > span {
            color: color.$PRIMARY-MAIN;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .card.addBtnCard {
        cursor: pointer;
      }
    }
  }
}
