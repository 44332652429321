@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 6px;
  // border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  > span {
    font-size: 14px;
    color: $PRIMARY-MAIN;
    font-weight: 500;
  }

  .card {
    background-color: $NEUTRAL-10;
    border-radius: 16px;
    width: 100%;
  }

  .fdContainer {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    cursor: auto;
    .fdHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      > p {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 2px;
        color: $NEUTRAL-100;
        > * {
          &:nth-child(2) {
            cursor: pointer;
          }
        }
      }
    }

    .fdContent {
      display: flex;
      flex-direction: column;
      .fdcDays {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));
        margin-top: 8px;
        > div {
          height: 22px;
          display: grid;
          place-items: center;
          font-size: 10px;
          color: $NEUTRAL-70;
          font-weight: 500;
          font-family: Roboto;
        }
      }

      .fdcDates {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));
        // &.months {
        //   grid-template-columns: 1fr 1fr 1fr;
        //   > div {
        //     aspect-ratio: 84/52 !important;
        //     font-size: 15px !important;
        //   }
        // }

        > div {
          height: 24px;
          // aspect-ratio: 1;
          display: grid;
          place-items: center;
          font-size: 11px;
          color: $NEUTRAL-100;
          border-radius: 38px;
          cursor: pointer;
          font-weight: 600;
          &:hover {
            background-color: $PRIMARY-SURFACE;
            font-weight: 500;
          }

          &.active {
            background-color: $PRIMARY-MAIN;
            color: $NEUTRAL-10;

            &.isRange {
              border-radius: 0;

              &.rangeStarter {
                border-radius: 12px 0 0 12px;
              }
              &.rangeFinisher {
                border-radius: 0 12px 12px 0;

                &.rangeStarter {
                  border-radius: 12px;
                }
              }
            }
          }

          &.disabled {
            color: $NEUTRAL-40;
            cursor: auto;
          }
        }
      }
    }

    > button {
      border: 2px solid $PRIMARY-MAIN;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      color: $PRIMARY-MAIN;
      font-weight: 500;
    }
  }
}
