@use "theme/color";
@use "theme/variables";
@use "theme/mixin";

.containerReportActivity {
  width: 100%;
  @include mixin.flexRow(0);
  justify-content: center;
  .mainContainListReport {
    padding: 20px;
    @include mixin.flexCol(1rem);
    width: 100%;
    max-width: 1440px;
    > strong {
      color: color.$NEUTRAL-100;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
    }

    .informationPersonil {
      @include mixin.flexRow(20px);
      align-items: center;
      .card {
        width: 100%;
        @include mixin.flexRow(11px);
        border: 1px solid color.$NEUTRAL-30;
        padding: 8px 12px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom: 3px solid #e78b00;
        background-color: color.$NEUTRAL-10;
        justify-content: space-between;
        > div {
          @include mixin.flexRow(11px);
          align-items: center;

          div:nth-child(1) {
            @include mixin.flexRow(0);
            background-color: color.$NEUTRAL-30;
            border-radius: 8px;
            padding: 4px;
          }
          div:nth-child(2) {
            @include mixin.flexCol(4px);
            span {
              color: color.$NEUTRAL-70;
              font-size: 10px;
              font-weight: 500;
            }
            > h3 {
              color: color.$NEUTRAL-100;
            }
          }
        }
        > div {
          .icon {
            cursor: pointer;
            color: color.$PRIMARY-MAIN;
          }
        }
      }
    }

    .cardLocation {
      width: 100%;
      // @include mixin.flexRow(1rem);
      // flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
      gap: 1rem;
      padding-bottom: 1rem;
      .card {
        overflow: hidden;
        display: flex;
        //   width: 281px;
        height: 287px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: color.$NEUTRAL-10;
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
          0px 10px 32px -4px rgba(24, 39, 75, 0.1);
        border-radius: 12px;
        transition: 0.3s ease;
        &:hover {
          box-shadow: none;
          box-shadow: 0 0 5px color.$NEUTRAL-30;
        }
        > img[alt="image-location"] {
          width: 100%;
          height: 190px;
          object-fit: cover;
        }
        .description {
          width: 100%;
          padding: 1rem;
          @include mixin.flexCol(12px);
          align-items: flex-start;
          .personil {
            @include mixin.flexCol(4px);
            > strong {
              color: color.$NEUTRAL-100;
              font-size: 14px;
              font-weight: 500;
            }
            div {
              @include mixin.flexRow(2px);
              color: color.$NEUTRAL-70;
              .peopleIcon {
                height: 16px;
                width: 16px;
              }
              span {
                font-size: 14px;
              }
            }
          }
          .btnCard {
            @include mixin.flexRow(4px);
            align-items: center;
            color: color.$PRIMARY-MAIN;
            cursor: pointer;
            span {
              font-size: 14px;
              font-weight: 500;
            }
            .arrowIcon {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              rotate: 180deg;
            }
          }
        }
      }
      .card.addBtnCard {
        cursor: pointer;
      }
    }
  }
}
