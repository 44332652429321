@import "../../../theme/variables.scss";

.container{
    display: flex;
    width: 620px;
    height: fit-content;
    max-height: 600px;
    flex-direction: column;
    gap: 12px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background-color: #fff;
    font-family: Roboto;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        >span{
            font-weight: 600;
            font-size: 16px;
            color: $NEUTRAL-100;
        }

        .iconClose{
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .body{
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;

        .cardBox{
            border: 1px solid $NEUTRAL-30;
            border-radius: 8px;

            .title{
                width: 100%;
                background-color: $NEUTRAL-30;
                border-radius: 8px 8px 0px 0px;
                padding: 12px 16px 12px 16px;
                font-weight: 600;
                font-size: 16px;
                color: $NEUTRAL-100;
            }

            .contentWrapper{
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 12px 16px 12px 16px;
                width: 100%;

                .childContent{
                    display: flex;
                    justify-content: space-between;

                    >:first-child {
                        font-weight: 400;   
                        font-size: 14px;
                        color: $NEUTRAL-90;
                    }
                    >:last-child {
                        font-weight: 400;   
                        font-size: 14px;
                        color: $NEUTRAL-100;
                    }
                }
                .divider{
                    width: 100%;
                    height: 1px;
                    background-color: $NEUTRAL-30;
                }
                .totalWrapper{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    font-weight: 500;
                    font-size: 14px;
                    color: $NEUTRAL-100;
                }
            }

        }
        .takeHomePayWrapper{
            display: flex;
            flex-direction: column;
            gap: 8px;

            .takeHomePay{
                display: flex;
                justify-content: space-between;
                align-items: center;

                >:first-child{
                    font-weight: 400;
                    font-size: 16px;
                    color: $NEUTRAL-100;
                }

                >:last-child{
                    font-weight: 500;
                    font-size: 20px;
                    color: $PRIMARY-MAIN;
                }
            }
            .status{
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                >:first-child{
                    font-weight: 400;
                    font-size: 16px;
                    color: $NEUTRAL-100;
                }
            }
        }
    }

    .footer{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 14px;
    }
}