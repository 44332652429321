@import "../../../theme/variables.scss";
@import "../../../theme/global.scss";

.containerModalIsEmpty {
  padding: 1rem 20px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 8px;
  background: $NEUTRAL-10;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  width: 480px;
  .headerModal {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .between {
      display: flex;
      gap: 10px;
      align-items: center;
      .excelIcon {
        padding: 1rem 12px;
        background-color: #d6ffeb;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    h2 {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-weight: 600;
    }
    .iconCloseModal {
      color: $NEUTRAL-100;
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }
  .descriptionModal {
    p {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .iconDownload {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
  .textUploadModal {
    padding: 8px;
    display: flex;
    gap: 12px;
    border-radius: 8px;
    background: $NEUTRAL-10;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    height: 72px;
    cursor: pointer;
    transition: box-shadow 0.4s;
    &:active {
      box-shadow: none;
    }
  }
  .uploadModal {
    border: 1px dashed $PRIMARY-MAIN;
    height: 193px;
    border-radius: 8px;
    padding: 28px 110px;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    align-items: center;
    justify-content: center;
    background: rgba(240, 240, 255, 0.2);
    .uploadDoc {
      height: 48px;
      width: 48px;
      flex-shrink: 0;
    }
    h2 {
      color: $PRIMARY-MAIN;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
    p {
      color: $NEUTRAL-80;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
    .excelIcon {
      padding: 12px;
      background-color: #d6ffeb;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .buttonModal {
    display: flex;
    width: 100%;
    justify-content: right;
    gap: 1rem;
  }
}
@media (max-width: 768px) {
  .containerIsEmpty {
    .wrapperEmpty {
      .headerText {
        h2 {
          font-size: 20px !important;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .uploadModal {
    padding: 0 !important;
  }
  .containerModalIsEmpty {
    width: 340px;
    .headerModal {
      h2 {
        font-size: 14px;
      }
    }
    .uploadModal {
      h2 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
.uploadingDocuments {
  cursor: progress;
  max-width: 480px;
  height: auto;
  width: auto;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: $NEUTRAL-10;
  @include flexCol(1rem);
  .header {
    @include flexRow(10px);
    align-items: center;
    .iconHead {
      flex-shrink: 0;
    }
    span {
      color: $NEUTRAL-100;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .lottieWrap {
    @include flexColumnAlignCenter();
    width: 100%;
    .lottie {
      width: 300px;
      height: 224px;
    }
  }
  .footContain {
    color: $NEUTRAL-90;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
