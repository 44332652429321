@mixin flexRow($gap: 0) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flexCol($rowGap: 0) {
  display: flex;
  flex-direction: column;
  row-gap: $rowGap;
}

@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin flexColumnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textEllipsis($lineClamp: 1) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  line-clamp: $lineClamp;
  -webkit-line-clamp: $lineClamp;
}

@mixin width($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-size($max, $min, $minpx) {
  font-size: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-weight($max, $min, $minpx) {
  font-weight: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin line-height($max, $min, $minpx) {
  line-height: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin fluid-image-size($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
  height: 100%;
}

@mixin top($max, $min, $minpx) {
  top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin gap($max, $min, $minpx) {
  gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding($max, $min, $minpx) {
  padding: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-top($max, $min, $minpx) {
  padding-top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-right($max, $min, $minpx) {
  padding-right: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-bottom($max, $min, $minpx) {
  padding-bottom: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-left($max, $min, $minpx) {
  padding-left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-block($max, $min, $minpx) {
  padding-block: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-inline($max, $min, $minpx) {
  padding-inline: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}
