@use "../../../theme/variables.scss" as *;

.container {
  background-color: $NEUTRAL-10;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  .expandButton {
    display: grid;
    place-items: center;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .tableWrapper {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      thead {
        z-index: 1;
        tr {
          th {
            padding: 12px 16px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
            white-space: nowrap;
            z-index: 1;
            background-color: $NEUTRAL-20;
            color: $NEUTRAL-100;
          }
        }
      }

      tbody {
        tr {
          border: 1px solid black;
          &:hover {
            background-color: #fafafa;
          }
          &[is_active="true"] {
            background-color: $PRIMARY-SURFACE;
          }
          td {
            padding: 12px 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            white-space: wrap;
            border-bottom: 1px solid $NEUTRAL-20;
            border-collapse: collapse;

            > span {
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              width: max-content;
            }
          }
          // &:not(:last-child) {
          //   td {
          //     border-bottom: 1px solid $NEUTRAL-20;
          //   }
          // }

          &.expandedRow {
            background-color: $NEUTRAL-20;
          }
        }
      }
      .emptyData {
        &:hover {
          background-color: unset;
        }
      }
    }
  }

  .tableWrapper::-webkit-scrollbar {
    display: none;
  }
  .tableWrapper {
    scrollbar-width: thin;
    scrollbar-color: $NEUTRAL-70 transparent;
  }
  .tableWrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .tableWrapper::-webkit-scrollbar-thumb {
    background-color: $NEUTRAL-70;
  }
  .paginationWrapper {
    padding: 10px 16px;
    background-color: $NEUTRAL-20;
  }
}
