.wrapperWithBg {
  width: 100%;
  background-image: url("../../../assets/Images/bg-permata-security.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  display: flex;
  justify-content: center;
}
