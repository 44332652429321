@use "theme/color.scss";
.wrapperSearch {
  &[isfocus="true"] {
    outline: 1px solid color.$PRIMARY-MAIN;
  }
  display: flex;
  align-items: center;
  gap: 10px;
  outline: 1px solid color.$NEUTRAL-40;
  border-radius: 8px;
  width: auto;
  padding: 12px;
  transition: 0.3s;
  height: 44px;
  justify-content: space-between;
  &[size="s"] {
    height: 35px !important;
    input {
      font-size: 12px !important;
    }
  }
  &[size="m"] {
    height: 40px;
    input {
      font-size: 14px;
    }
  }
  &:hover {
    outline-color: color.$PRIMARY-HOVER;
  }
  input {
    font-size: 1rem;
    border: none;
    width: 100%;
    background-color: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: color.$NEUTRAL-50;
    }
  }
  .iconSearch {
    cursor: pointer;
    flex-shrink: 0;
    color: #bbc3ce;
    transition: 0.2s;
    &:hover {
      color: color.$PRIMARY-MAIN;
    }
  }
}

.wrapperSearch[size_searchbar="s"] {
  height: 35px;
  input {
    font-size: 12px;
  }
}
.wrapperSearch[size_searchbar="m"] {
  height: 40px;
  input {
    font-size: 14px;
  }
}
