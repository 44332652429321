$NEUTRAL-0: #e5e5e5;
$NEUTRAL-10: #ffffff;
$NEUTRAL-20: #edeff2;
$NEUTRAL-30: #dee2e7;
$NEUTRAL-40: #cdd2db;
$NEUTRAL-50: #bbc3ce;
$NEUTRAL-60: #a9b3c1;
$NEUTRAL-70: #919eb0;
$NEUTRAL-80: #718198;
$NEUTRAL-90: #556377;
$NEUTRAL-100: #3e4856;

$PRIMARY-MAIN: #2c5364;
$PRIMARY-SURFACE: #e6f0f4;
$PRIMARY-HOVER: #244451;
$PRIMARY-PRESSED: #112027;
$PRIMARY-BACKGROUND: rgba(212, 212, 232, 0.1);

$DANGER-MAIN: #d42701;
$DANGER-SURFACE: rgba(240, 44, 1, 0.2);
$DANGER-OUTLINE: #fa2d00;
$DANGER-BASE: #f5610d;

$ALERT-MAIN: #eed202;
$ALERT-SURFACE: #f9f6d3;
$ALERT-OUTLINE: #feeb5d;

$SUCCESS-MAIN: #22970f;
$SUCCESS-SURFACE: #ceead3;
$SUCCESS-OUTLINE: #f4fff2;

$STROKE: #d9d9d9;

$SHADOW-100: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
  0px 4px 4px -2px rgba(24, 39, 75, 0.08);
$SHADOW-300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
  0px 8px 16px -6px rgba(24, 39, 75, 0.08);