@import '../../../theme/variables.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    &.open {
        opacity: 1;
        transform: scale(1);
    }

    &.close {
        opacity: 0;
        transform: scale(0.95);
        pointer-events: none;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        width: 100vw;
        padding: 16px 32px 16px 32px;
        gap: 10px;
        background-color: #000;

        > span {
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            color: $NEUTRAL-10;
        }

        .backButton {
            background: 'none';
        }
    }

    .modal {
        padding: 20px;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;

        &.pdfContent {
            align-items: flex-start;
        }

        .content {
            display: flex;
            width: 60%;
            height: 90%;
            justify-content: center;
            align-items: center;

            .documentWrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .loadingText {
                background-color: $NEUTRAL-10;
                font-family: Poppins;
                font-weight: 400;
                font-size: 12px;
                color: $PRIMARY-MAIN;
                border-radius: 8px;
                padding: 8px;
            }
            .noDataText {
                align-self: center;
                background-color: $NEUTRAL-10;
                font-family: Poppins;
                font-weight: 400;
                font-size: 12px;
                color: $PRIMARY-MAIN;
                border-radius: 8px;
                padding: 8px;
            }
            .errorText {
                background-color: $NEUTRAL-10;
                font-family: Poppins;
                font-weight: 400;
                font-size: 12px;
                color: $DANGER-MAIN;
                border-radius: 8px;
                padding: 8px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .overlay {
        .modal {
            .content {
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 516px) {
    .overlay {
        &.hideOnMobile {
            display: none !important;
        }
    }
}
