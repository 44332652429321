@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  >img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;
  }

  .editButton {
    position: absolute;
    background-color: $NEUTRAL-100;
    color: $NEUTRAL-10;
    display: grid;
    place-items: center;
    border-radius: 50%;
    bottom: -2px;
    right: -2px;
    border: 2px solid $NEUTRAL-10;
    overflow: hidden;
  }
}