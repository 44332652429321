@use "theme/color.scss";
@use "theme/global.scss";
@use "theme/mixin.scss";

.viewLoadingCreate {
  background-color: color.$NEUTRAL-10;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  .loadingElement {
    height: 60px;
    width: 100%;
  }
}

.modalUpdateAssignMent {
  @include mixin.flexCol(1rem);
  max-width: 427px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  .header {
    @include mixin.flexRow(8px);
    width: 100%;
    align-items: center;
    > strong {
      color: color.$NEUTRAL-100;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .buttonAction {
    @include mixin.flexRow(8px);
    align-items: center;
    justify-content: flex-end;
  }
}

.containerListEmployee {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapperListEmployee {
    width: 100%;
    max-width: 1440px;
    .between {
      position: relative;
      display: flex;
      justify-content: space-between;
      .dropdownAddEmployee {
        width: 159px;
        position: absolute;
        background-color: color.$NEUTRAL-10;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        top: 100px;
        z-index: 99;
        box-shadow: 0 3px 6px color.$NEUTRAL-50;
        div {
          padding: 6px 8px;
          display: flex;
          gap: 4px;
          align-items: center;
          color: color.$PRIMARY-MAIN;
          .icon {
            color: color.$PRIMARY-MAIN;
            height: 14px;
            width: 14px;
          }
          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .headerList {
      display: flex;
      flex-direction: column;
      // row-gap: 20px;
      row-gap: 12px;
      .header {
        @include mixin.flexRow(12px);
        align-items: center;
        .backIcon {
          cursor: pointer;
          flex-shrink: 0;
        }
        .headerText {
          color: color.$NEUTRAL-100;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
        }
      }

      .wrapperFilter {
        display: flex;
        gap: 12px;
        .uploadEmployee {
          height: 100%;
        }
      }
      .wrapperExpand {
        display: flex;
        flex-direction: column;
        .list {
          display: flex;
          gap: 1rem;
          align-items: center;
          .textLeft {
            color: color.$NEUTRAL-50;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            // width: 119px;
            width: 87px;
          }
          .textRight {
            color: color.$NEUTRAL-100;
            font-size: 12px;
            line-height: 16px;
            padding-block: 8px;
            text-align: start;
          }
        }
        .detail {
          color: color.$PRIMARY-MAIN;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .table {
        border: 1px solid color.$NEUTRAL-20;
        border-radius: 8px;
        // overflow: hidden;
      }
      .fieldTable {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .filtering {
          gap: 1rem;
          display: flex;
          align-items: center;
          width: 100%;
          .filter {
            display: flex;
            align-items: center;
            gap: 6px;
            border: 2px solid color.$PRIMARY-MAIN;
            padding: 8px 12px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
              border-color: color.$PRIMARY-HOVER;
              > span {
                color: color.$PRIMARY-HOVER;
              }
              > .iconFilter {
                color: color.$PRIMARY-HOVER;
              }
            }
            > span {
              color: color.$PRIMARY-MAIN;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .btnUploading {
            width: auto;
            display: flex;
            align-items: center;
            justify-self: center;
            align-self: center;
            gap: 6px;
            border: 2px solid color.$PRIMARY-MAIN;
            padding: 8px 12px;
            border-radius: 8px;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
              border-color: color.$PRIMARY-HOVER;
              > span {
                color: color.$PRIMARY-HOVER;
              }
              > .icon {
                color: color.$PRIMARY-HOVER;
              }
            }
            > span {
              color: color.$PRIMARY-MAIN;
              font-size: 14px;
              font-weight: 500;
              width: fit-content;
              width: 114px;
            }
            > .icon {
              flex-shrink: 0;
              width: fit-content;
            }
          }
          @media (max-width: 851px) {
            width: 100%;
            flex-direction: column;
            display: grid;
            grid-template-columns: auto;
          }
          .wrapFilterMobile {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            .filter {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
          .btnUploading {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            > span {
              width: auto;
            }
            .dropdownAddEmployee {
              width: 159px;
              position: absolute;
              background-color: color.$NEUTRAL-10;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              top: 38px;
              z-index: 99;
              box-shadow: 0 3px 6px color.$NEUTRAL-50;
              div {
                padding: 6px 8px;
                display: flex;
                gap: 4px;
                align-items: center;
                color: color.$PRIMARY-MAIN;
                .icon {
                  color: color.$PRIMARY-MAIN;
                  height: 14px;
                  width: 14px;
                }
                span {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }
          }
        }
        .btnAdd {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 12px;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.3s ease;
          width: 100%;
          justify-content: flex-end;
          &:hover {
            border-color: color.$PRIMARY-HOVER;
            > span {
              color: color.$PRIMARY-HOVER;
            }
            > .iconAdd {
              color: color.$PRIMARY-HOVER;
            }
          }
          > span {
            color: color.$PRIMARY-MAIN;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .containerListEmployee {
    .wrapperListEmployee {
      .between {
        width: 100%;
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        .wrapperFilter {
          display: flex;
          flex-direction: column;
          .uploadEmployee {
            display: none;
          }
        }
        .addEmployee {
          display: none;
        }
      }
    }
  }
}
// MODAL
.containerModal {
  padding-inline: "1rem";
  width: "100%";
  display: "flex";
  justify-content: "center";

  .wrapperModalDropdown {
    padding: 1rem 20px;
    border-radius: 8px;
    background-color: color.$NEUTRAL-10;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 409px;
    text-align: start;

    .headerModal {
      display: flex;
      gap: 10px;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
      }
      h2 {
        color: color.$NEUTRAL-100;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        width: 293px;
      }

      .closeModal {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .desc {
      .paragraph {
        color: color.$NEUTRAL-90;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .wrapperButton {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
@media (max-width: 600px) {
  .wrapperModalDropdown {
    width: calc(409px - 70px);
  }
}

.uploadingDocuments {
  cursor: progress;
  max-width: 480px;
  height: auto;
  width: auto;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: color.$NEUTRAL-10;
  @include mixin.flexCol(1rem);
  .header {
    @include mixin.flexRow(10px);
    align-items: center;
    .iconHead {
      flex-shrink: 0;
    }
    span {
      color: color.$NEUTRAL-100;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .lottieWrap {
    @include mixin.flexColumnAlignCenter();
    width: 100%;
    .lottie {
      width: 300px;
      height: 224px;
    }
  }
  .footContain {
    color: color.$NEUTRAL-90;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
