@import "../../../../../theme/global.scss";
@import "../../../../../theme/variables.scss";

.containerDetailLocation {
  @include flexCol(20px);
  width: 100%;
  justify-content: flex-start;
  padding: 1rem;
  max-width: 1440px;
  .header {
    @include flexRow(12px);
    align-items: center;
    justify-content: space-between;
    > div {
      @include flexRow(12px);
      align-items: center;
      .back {
        cursor: pointer;
        height: 20px;
        width: 20px;
        color: $NEUTRAL-100;
      }
      > h3 {
        color: $NEUTRAL-100;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .settings {
      cursor: pointer;
      height: 32px;
      width: 32px;
      flex-shrink: 0;
      color: $NEUTRAL-60;
      transition: 0.3s;
      &:hover {
        color: $PRIMARY-HOVER;
      }
    }
  }
  .viewerMaps {
    border-radius: 8px;
    width: 100%;
    border: 1px solid $NEUTRAL-30;
  }
  .informationPersonil {
    @include flexRow(20px);
    align-items: center;

    .card {
      background-color: $NEUTRAL-10;

      width: 100%;
      @include flexRow(11px);
      border: 1px solid $NEUTRAL-30;
      padding: 8px 12px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom: 3px solid $PRIMARY-MAIN;
      div:nth-child(1) {
        @include flexRow(0);
        background-color: $NEUTRAL-30;
        border-radius: 8px;
        padding: 4px;
      }
      div:nth-child(2) {
        @include flexCol(4px);
        span {
          color: $NEUTRAL-70;
          font-size: 10px;
          font-weight: 500;
        }
        > h3 {
          color: $NEUTRAL-100;
        }
      }
    }
  }
  .cardProgress {
    @include flexRow(22px);
    align-items: center;
    .card {
      background-color: $NEUTRAL-10;
      width: 100%;
      border: 1px solid $NEUTRAL-20;
      border-radius: 12px;
      box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
      padding: 1rem;
      height: 221px;
      overflow-y: hidden;

      strong {
        color: $NEUTRAL-100;
        font-weight: 500;
        font-size: 14px;
      }

      .borderProgress {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 313px;
          height: 313px;
          transform: rotate(-90deg);
        }

        .circularChart {
          fill: none;
          stroke-width: 2.8;
        }

        .circleBg {
          stroke: #d9d9d9;
          stroke-width: 2.8;
        }

        .circle {
          stroke: $SUCCESS-MAIN; /* Warna progress */
          stroke-width: 2.8;
          stroke-linecap: round; /* Ujung progress melingkar */
          transition: stroke-dasharray 0.6s ease;
        }

        // .percentage {
        //   fill: #d9d9d9;
        //   font-size: 0.5em;
        //   //   transform: rotate(90deg);
        //   text-anchor: middle;
        // }

        // .number {
        //   position: absolute;
        //   bottom: 0;
        //   span {
        //     color: $NEUTRAL-80;
        //     font-size: 12px;
        //   }
        // }
        .descPercentage {
          top: 70px;
          position: absolute;
          @include flexCol(4px);
          align-items: center;
          color: $PRIMARY-MAIN;

          > strong {
            color: $PRIMARY-MAIN;
            font-size: 32px;
            font-weight: 800;
            line-height: 40px;
          }
          > span {
            font-size: 14px;
            font-weight: 400;
            color: $NEUTRAL-60;
          }
        }
      }
    }
  }
  .openedModalLocation {
    position: absolute;
    transform: translateX(200px);
    width: 100vw;
    height: 100vh;
  }

  .tableRadius {
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    .header {
      @include flexBetween();
      padding: 14px;
      padding-bottom: 8px;
      align-items: center;
      background-color: $NEUTRAL-10;
      > span {
        color: $NEUTRAL-100;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      .refresh {
        cursor: pointer;
        padding: 4px;
        border: 2px solid $PRIMARY-MAIN;
        border-radius: 8px;
        @include flexRow(0);
        align-items: center;
        &:hover {
          background-color: $PRIMARY-SURFACE;
        }
      }
    }
    .table {
      // border-radius: 8px;
      overflow: hidden;
      .imgAndName {
        display: flex;
        gap: 8px;
        align-items: center;
        > img {
          border-radius: 4px;
          height: 32px;
          width: 32px;
          &.isProfilePic {
            border-radius: 50%;
            height: 20px;
            width: 20px;
          }
        }
        > span {
          font-size: 14px;
          color: $NEUTRAL-100;
        }
      }
    }
  }
}
