@import "../../../theme/variables.scss";

.calendar {
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  overflow: clip;

  .cHeader {
    background-color: $NEUTRAL-20;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    border-bottom: 1px solid $NEUTRAL-30;

    >div {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #6F6F70;
      line-height: 20px;

      &:not(:last-child) {
        border-right: 1px solid $NEUTRAL-30;
      }
    }
  }


  .container {

    .cWrapper  {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    
      >div {
        padding: 8px;
        background-color: $NEUTRAL-10;
        display: flex;
        flex-direction: column;
        gap: 10.45px;
    
        &.withBr {
          border-right: 1px solid $NEUTRAL-30;
    
        }
    
        &.withBb {
          border-bottom: 1px solid $NEUTRAL-30;
        }

        &.empty {
          background-color: $NEUTRAL-10;
        }
    
        &.disabled,
        &.weekend {
          background-color: #f4f4f4 !important;
        }
    
        &.attend {
          background-color: #F4FFF2;
        }
    
        &.sick {
          background-color: #FEEDFF;
    
          >p {
    
            >span {
              &:nth-child(2) {
                color: #D30CDE;
              }
            }
          }
        }
    
        &.leave {
          background-color: #FFFCE6;
    
          >p {
    
            >span {
              &:nth-child(2) {
                color: #AD9800;
              }
            }
          }
        }
        &.permit {
          background-color: $PRIMARY-SURFACE;
    
          >p {
    
            >span {
              &:nth-child(2) {
                color: $PRIMARY-MAIN;
              }
            }
          }
        }
        &.absence {
          background-color: #FFF4F2;
    
          >p {
    
            >span {
              &:nth-child(2) {
                color: #E02200;;
              }
            }
          }
        }
    
        >p {
          display: flex;
          align-items: center;
          gap: 6px;
    
          >span {
            font-size: 14px;
            font-weight: 500;
          }
        }
    
        >div {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $NEUTRAL-80;
    
          >p {
            font-size: 12px;
            font-weight: 500;
          }
        }
    
      }
    }
   
  }
 
}

