@use "../../../theme/color" as *;
@use "../../../theme/mixin" as *;

.wrapperLayoutDetailClient {
  height: 100%;
  min-height: calc(100vh - 56px);
  width: 100%;
  padding: 22px;
  @include flexCol(20px);
  // background-color: $PRIMARY-BACKGROUND;
  max-width: 1440px;
  .profileClient {
    @include flexCol(11px);
    > h2 {
      color: #000;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
    .mainProfile {
      width: 100%;
      height: auto;
      border-radius: 8px;
      background: $NEUTRAL-10;
      box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
      padding-bottom: 1rem;
      .header {
        @include flexRow(12px);
        align-items: center;
        padding: 1rem;
        padding-bottom: 4px;
        > img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
        > .imgAvatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $NEUTRAL-20;
          @include flexRow(0);
          align-items: center;
          justify-content: center;
          > h1 {
            color: $NEUTRAL-60;
            line-height: normal;
          }
        }
        > h4 {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .divider {
        background: #edeff2;
        height: 1px;
        width: 100%;
        margin-block: 12px;
      }
      .labelAndValue {
        // @include flexBetween();
        padding-inline: 1rem;
        .label {
          @include flexCol(12px);
          > div {
            @include flexBetween();
            width: 100%;
            > div {
              @include flexRow(8px);
              align-items: center;
              > span {
                color: $NEUTRAL-80;
                font-size: 12px;
              }
            }
            > .value {
              color: $NEUTRAL-100;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .viewRowTable {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 20px;
    .pic,
    .contact,
    .coordinator {
      @include flexCol(4px);
      width: 100%;
      > h3 {
        color: $PRIMARY-MAIN;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      > .card {
        padding: 1rem;
        width: 100%;
        height: 353px;
        border-radius: 8px;
        background: $NEUTRAL-10;
        box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
          0px 8px 16px -6px rgba(24, 39, 75, 0.08);
        overflow: hidden;

        .tableRadius {
          border: 1px solid $NEUTRAL-20;
          border-radius: 8px;
          overflow: hidden;
        }
        .wrapEmptyTableClient {
          @include flexCol(0);
          align-items: center;
          min-height: 270px;
          justify-content: center;
          > img {
            width: 139.939px;
            height: 136.98px;
            margin-bottom: 12px;
          }
          > h2 {
            color: $NEUTRAL-100;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
          }
          > span {
            color: $NEUTRAL-60;
            font-size: 14px;
          }
        }
        .wrapperEmptycoor {
          @include flexCol(12px);
          align-items: center;
          width: 100%;
          padding: 1rem;
          > img {
            width: 204px;
            height: 156.907px;
          }
          > span {
            color: $NEUTRAL-50;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
        .modalAddCoordinator {
          @include flexCol(1rem);
          max-width: 427px;
          width: 100%;
          background-color: #fff;
          border-radius: 8px;
          padding: 1rem;
          .header {
            @include flexRow(8px);
            width: 100%;
            align-items: center;
            > strong {
              color: $NEUTRAL-100;
              font-size: 16px;
              font-weight: 600;
            }
          }
          .buttonAction {
            @include flexRow(8px);
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
    .coordinator {
      .between {
        @include flexBetween();
        > h3 {
          color: $PRIMARY-MAIN;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .tableRadius {
          border-radius: 8px;
        }
      }
    }
    .pic,
    .contact {
      .namePIC {
        @include flexRow(8px);
        align-items: center;
        > img {
          border-radius: 50%;
        }
        > div {
          @include flexCol(0);
          p {
            color: $NEUTRAL-100;
            font-size: 14px;
            line-height: normal;
          }
          span {
            color: $NEUTRAL-80;
            font-size: 10px;
            line-height: normal;
          }
        }
      }
      .emailCopy {
        @include flexRow(4px);
        align-items: center;
        .icon {
          cursor: pointer;
          flex-shrink: 0;
          color: $PRIMARY-MAIN;
          &:hover {
            color: $PRIMARY-HOVER;
          }
        }
      }
    }
    .wrapperTableToScroll {
      overflow: auto;
      height: 100%;
    }
  }
}
