@use "../../../theme/variables.scss" as *;

.container {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  > span {
    font-size: 14px;
    color: $PRIMARY-MAIN;
    font-weight: 500;
    flex-grow: 1;
  }

  .card {
    position: absolute;
    background-color: $NEUTRAL-10;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    z-index: 2;
    top: calc(100% + 4px);
    border-radius: 16px;
    padding: 16px;
  }

  .fdContainer {
    width: 284px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 276px;
    cursor: auto;

    .fdHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      > p {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 2px;

        > * {
          &:nth-child(2) {
            cursor: pointer;
          }
        }
      }
    }

    .fdContent {
      display: flex;
      flex-direction: column;

      .fdcDays {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));

        > div {
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          font-size: 10px;
          color: $NEUTRAL-70;
          font-weight: 500;
          font-family: Roboto;
        }
      }

      .fdcDates {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(36px, 1fr));

        &.months {
          grid-template-columns: 1fr 1fr 1fr;

          > div {
            aspect-ratio: 84/52 !important;
            font-size: 15px !important;
          }
        }

        > div {
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          font-size: 12px;
          color: $NEUTRAL-100;
          border-radius: 12px;
          cursor: pointer;

          &:hover {
            background-color: $PRIMARY-SURFACE;
            font-weight: 500;
          }

          &.active {
            background-color: $PRIMARY-MAIN;
            color: $NEUTRAL-10;

            &.isRange {
              border-radius: 0;

              &.rangeStarter {
                border-radius: 12px 0 0 12px;
              }
              &.rangeFinisher {
                border-radius: 0 12px 12px 0;

                &.rangeStarter {
                  border-radius: 12px;
                }
              }
            }
          }

          &.disabled {
            color: $NEUTRAL-40;
            cursor: auto;
          }
        }
      }
    }

    > button {
      border: 2px solid $PRIMARY-MAIN;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      color: $PRIMARY-MAIN;
      font-weight: 500;
    }
  }

  &.modalContainer {
    // width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 0 !important;
    border-color: transparent;
    .card {
      top: 1.5rem;
      left: -180px !important;
    }
  }
}
