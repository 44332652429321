@import "../../../../../theme/variables.scss";

.content {
    max-height: 611px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
  }

.goodsNameWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    >:first-child{
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL-100;
    }
    >:last-child{
        font-family: Roboto;
        font-size: 10px;
        font-weight: 400;
        color: $NEUTRAL-80;

    }
}

.photoWrapper{
    display: flex;
    // padding: 8px;
    align-items: center;
    justify-content: center;
}