@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 24px;
  overflow: auto;
  max-height: 611px;

  .wrapperTabs {
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    .rightTabs {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .attendances {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .types {
      display: flex;
      align-items: center;
      gap: 12px;

      >* {
        width: 100%;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        gap: 8px;

        >span {
          font-weight: 500;

          &:first-child {
            flex-grow: 1;
            font-size: 16px;
          }

          &:last-child {
            font-size: 20px;
          }
        }
      }
    }

    
  }

  .leaves {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .indicators {
      display: flex;
      align-items: center;
      gap: 16px;

      .indicator {
        width: 242px;
        padding: 8px 16px;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 10px;

        >span {
          flex-grow: 1;
          font-size: 16px;
          font-weight: 500;
        }

        .iValue {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: $PRIMARY-SURFACE;
          padding: 4px 8px;
          border-radius: 4px;

          >span {
            font-size: 20px;
            font-weight: 500;
            color: $NEUTRAL-100;
          }

          >div {
            background-color: $NEUTRAL-40;
            padding: 2px 4px;
            font-size: 12px;
            font-weight: 500;
            color: $NEUTRAL-100;
            padding: 2px 4px;
            border-radius: 3px;
          }
        }
      }
    }

    .tableWrapper {
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;
      overflow: clip;
      min-height: 300px;

      .leaveDate {
        display: flex;
        flex-direction: column;

        >p {
          display: flex;
          align-items: center;
          gap: 4px;

          >span {
            font-size: 12px;
            color: $NEUTRAL-100;
          }
        }
      }

      .desc {
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
}
    }
  }
}