@use "theme/color";
@use "theme/mixin";
@use "theme/variables";

.wrapModalActivities {
  transition: 0.6s ease;
  background-color: color.$NEUTRAL-10;
  width: 661px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: -50%;
  .headerModal {
    width: 100%;
    @include mixin.flexBetween();
    border-bottom: 1px solid color.$NEUTRAL-30;
    padding-bottom: 6px;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: color.$NEUTRAL-10;
    border-top-left-radius: 8px;

    span {
      color: color.$NEUTRAL-100;
      font-size: 16px;
      font-weight: 500;
    }
    .icon {
      color: color.$NEUTRAL-100;
      cursor: pointer;
    }
  }
  .mainContentModal {
    @include mixin.flexCol(1rem);
    padding: 1rem;
    border-top-left-radius: 8px;

    .status {
      @include mixin.flexCol(4px);
      span {
        font-size: 14px;
        font-weight: 500;
        color: color.$NEUTRAL-70;
      }
      p {
        color: color.$NEUTRAL-100;
      }
      > img[alt="image-location"] {
        width: 100%;
        height: 253px;
        border-radius: 16px;
        object-fit: cover;
        background-color: color.$NEUTRAL-40;
        animation: fadeOut infinite 0.8s ease-in alternate;
      }
    }
  }
}
.wrapModalActivities[is-open="true"] {
  position: absolute;
  right: 0;
}

@keyframes fadeOut {
  0% {
    background-color: color.$NEUTRAL-40;
  }
  50% {
    background-color: color.$NEUTRAL-30;
  }
  100% {
    background-color: color.$NEUTRAL-20;
  }
}