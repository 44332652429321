@use "theme/color" as *;
@use "theme/mixin" as *;

@mixin dividerLine($bg: $NEUTRAL-30, $h: 1.5px) {
  background-color: $bg;
  height: $h;
  width: 100%;
}
.errorContain {
  width: 100%;
  height: calc(100vh - 56px);
}

.containerAnnouncementsPage {
  padding: 20px;
  @include flexCol(20px);
  height: calc(100vh - 56px);
  max-width: 1440px;
  width: 100%;
  > .header {
    @include flexBetween();
    .backButton {
      @include flexRow(12px);
      align-items: center;
      .iconBack {
        cursor: pointer;
      }
    }
    > h1 {
      color: $NEUTRAL-100;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
    > div {
      .searchBar {
        width: 295px;
      }
      @include flexRow(12px);
      align-items: center;
    }
  }
  .mainContain {
    @include flexRow(0);
    width: 100%;
    overflow: hidden;
    height: 100%;
    &.true {
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;
      box-shadow: 0 0 6px $NEUTRAL-30;
    }
    .headerSectionLeft {
      @include flexRow(0);
      align-items: center;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 99;
      background-color: $NEUTRAL-10;

      .searchBar {
        width: 100%;
        .stylesSearchBar {
          border: unset;
          outline: unset;
          border-radius: unset;
          border-top-left-radius: 12px;
          border-bottom: 1px solid $NEUTRAL-30;
        }
      }
    }
    .left {
      width: 100%;
      height: 100%;
      background-color: $NEUTRAL-10;
      background-color: transparent;
      @include flexCol(12px);
      overflow-y: auto;

      scrollbar-width: thin;
      scrollbar-color: $NEUTRAL-40 $NEUTRAL-20;
      // scrollbar-color: red $NEUTRAL-20;
      ::-webkit-scrollbar {
        display: none;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: $NEUTRAL-70;
      }

      &.true {
        border-right: 1px solid $NEUTRAL-30;
        gap: 0;
        .viewRowBetween {
          border-radius: unset;
        }
      }
      .viewRowBetween {
        cursor: pointer;
        padding: 12px 1rem;
        background: $NEUTRAL-10;
        @include flexBetween();
        gap: 20px;
        align-items: center;
        position: relative;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        .activeIndicator {
          position: absolute;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: $PRIMARY-MAIN;
        }
        &.true {
          background-color: $NEUTRAL-20;
          border: unset;
          &:hover {
            background-color: $NEUTRAL-20;
          }
        }
        &:hover {
          background-color: #f9f9f9;
        }
        .announcements {
          @include flexRow(12px);
          align-items: center;
          .defaultImg {
            flex-shrink: 0;
            width: 60px;
            height: 60px;
            border-radius: 6px;
            background: linear-gradient(
              94deg,
              #274551 0.22%,
              #203a43 49.5%,
              #2c5364 99.78%
            );
            @include flexRow(0);
            align-items: center;
            justify-content: center;
          }
          > img {
            border-radius: 6px;
            width: 60px;
            height: 60px;
            object-fit: cover;
          }
          > div {
            @include flexCol(0);
            h2 {
              @include textEllipsis(1);
              color: $NEUTRAL-100;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
            }
            p {
              color: $NEUTRAL-60;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              @include textEllipsis(1);
              max-height: 40px;
            }
          }
        }
        .createdAt {
          width: 220px;
          flex-shrink: 0;
          @include flexRow(12px);
          align-items: center;
          &.true {
            width: 130px;
          }
          .date {
            &.dNone {
              display: none;
            }
            @include flexCol(8px);
            align-items: flex-end;
            > span {
              color: $NEUTRAL-60;
              font-size: 12px;
              line-height: 16px;
            }
            .views {
              @include flexRow(2px);
              align-items: center;
              > span {
                color: $NEUTRAL-60;
                font-size: 12px;
              }
            }
          }
          .arrowrightIcon {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }
    }
    .right {
      width: 0;
      @include flexCol(0);
      background-color: $NEUTRAL-10;
      opacity: 0;
      transition: width 0.6s ease-in-out;

      &.true {
        width: 100%;
        opacity: 1;
        transition: width 1.2s cubic-bezier(0.23, 1, 0.32, 1);
      }
      .editorTools {
        overflow-y: auto;
      }
      .headerRight {
        @include flexCol(0);
        padding: 10px 20px;
      }
      .divider {
        @include dividerLine();
      }
      .inputFiles {
        padding: 16px 20px;
        @include flexCol(10px);
        > label {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
        .showedImage {
          position: relative;
          width: 80px;
          height: 80px;
          border: 1px solid $NEUTRAL-20;
          border-radius: 6px;
          // overflow: hidden;
          > img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            flex-shrink: 0;
            border-radius: 6px;
          }
          .iconDelete {
            position: absolute;
            top: -5px;
            right: -5px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $DANGER-MAIN;
            color: #fff;
            @include flexCol(0);
            align-items: center;
            justify-content: center;
          }
        }
        .uploadFile {
          @include flexRow(0);
          width: 80px;
          height: 80px;
          justify-content: center;
          align-items: center;
          padding: 28px;
          border-radius: 8px;
          border: 1px dashed $NEUTRAL-40;
          cursor: pointer;
          &:hover {
            background-color: $NEUTRAL-20;
          }
        }
      }
      .dividerTextEditor {
        @include dividerLine($NEUTRAL-30, 1px);
      }
    }
  }
}
