@import "../../../../theme/variables.scss";
@import "../../../../theme/global.scss";
.viewModalChangeClient {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  @include flexCol(8px);
  width: 550px;
  padding: 1rem;
  overflow: auto;
  .header {
    @include flexBetween();
    margin-bottom: calc(1rem - 8px);
    > div {
      @include flexRow(8px);
      align-items: center;
      > span {
        color: black;
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .closeIcon {
      cursor: pointer;
      color: $NEUTRAL-100;
      flex-shrink: 0;
    }
  }
  .description {
    color: $NEUTRAL-90;
    font-size: 14px;
  }
  .tableRadius {
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    overflow: hidden;
  }
  .saveButton {
    @include flexRow(0);
    justify-content: flex-end;
    margin-top: calc(1rem - 8px);
  }
  .loadClient {
    @include flexCol(1rem);
    align-items: center;
    > div {
      @include flexCol(4px);
      align-items: center;
      > p {
        font-size: 12px;
        color: $NEUTRAL-100;
      }
      > div {
        @include flexRow(4px);
        align-items: center;
        font-weight: 600;
        color: $PRIMARY-MAIN;
      }
    }
  }
}
.wrapTable {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
